import React, { Component } from "react";
class SectionTitle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, print } = this.props;
    return (
      <header className={`component-header ${print?"print":""}` }>
        <h2>{title}</h2>
      </header>
    );
  }
}
export default SectionTitle;
