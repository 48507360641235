import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";
import Drawer from "../shared/Drawer";

class MxDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderDocuments(documents = null) {
    if (documents) {
      return documents.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <a
              className="detail-link"
              rel="noreferrer"
              href={item.url}
              target="_blank"
            >
              document {index + 1}
            </a>
            <br />
          </React.Fragment>
        );
      });
    }
    return;
  }
  renderGallons(gallons) {
    if (this.props.data.mx_area == "fuel") {
      return (
        <React.Fragment>
          <div className="data">Gallons:</div>
          <div className="data detail">{gallons}</div>
        </React.Fragment>
      );
    } else {
      return;
    }
  }
  // getOptions(data) {
  //   console.log(data);
  //   return data.map((item, index) => {
  //     if (item.id == this.props.data.mechanic[0]) {
  //       return (
  //         <option selected key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     }
  //   });
  // }

  render() {
    const {
      date,
      tachometer,
      work_performed,
      id,
      aircraft_details,
      n_number_string,
      aircraft_total_time,
      engine_total_time,
      tsmoh,
      propeller_total_time,
      mx_area,
      work_required,
      next_due_date,
      next_due_hours,
      mechanic,
      inspector,
      vendor,
      mx_type,
      documents,
      gallons,
    } = this.props.data;
    return (
      <section className="entry-details tabular">
        <div className="detail-head view-log-entry grid">
          <div>Entry Information</div>
          <div>Sqawk</div>
          <div>Correction</div>
          <div>Details</div>
        </div>
        <section className="view-log-entry grid">
          <section>
            <div className="data">Aircraft:</div>
            <div className="data detail">{n_number_string}</div>
            <div className="data">Date:</div>
            <div className="data detail"> {date}</div>
            <div className="data">Category:</div>
            <div className="data detail"> {mx_area}</div>
            <div className="data">Mx Type:</div>
            <div className="data detail"> {mx_type}</div>
            <div className="data">Tachometer:</div>
            <div className="data detail"> {tachometer.toFixed(2)}</div>
            <div className="data">AC Total Time:</div>
            <div className="data detail"> {aircraft_total_time.toFixed(2)}</div>
            <div className="data">Engine Total Time:</div>
            <div className="data detail"> {engine_total_time.toFixed(2)}</div>
            <div className="data">TSMOH:</div>
            <div className="data detail"> {tsmoh.toFixed(2)}</div>
            <div className="data">Prop Total Time:</div>
            <div className="data detail">{propeller_total_time.toFixed(2)}</div>
          </section>
          <section>
            <div className="data">Work Required:</div>
            <div className="data detail log-entry-data">{work_required}</div>
          </section>
          <section>
            <div className="data">Work Performed:</div>
            <div className="data detail log-entry-data">{work_performed}</div>
            {this.renderGallons(gallons)}
          </section>
          <section>
            <div className="data">Next Due:</div>
            <div className="data detail">
              {next_due_date}
              <br />
              {next_due_hours}
            </div>
            <div className="data">Mechanic:</div>
            <div className="data detail">
              {mechanic ? this.props.mechanics_obj[mechanic[0]].name : ""}
            </div>
            <div className="data">Inspector:</div>
            <div className="data detail">
              {inspector ? this.props.mechanics_obj[inspector[0]].name : ""}
            </div>
            <div className="data">Vendor:</div>
            <div className="data detail">
              {vendor ? this.props.vendors_obj[vendor[0]].company : ""}
            </div>
            <div className="data">Documents</div>
            <div className="data detail">{this.renderDocuments(documents)}</div>
          </section>
        </section>
      </section>
    );
  }
}
export default MxDetails;
