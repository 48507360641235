import React, { Component } from "react";

class AdEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: {
        ad_number: "",
        mx_area: "",
        subject: "",
        effective_date: "",
        recurring: false,
        recurring_interval_hours: 0,
        recurring_interval_months: 0,
        compliance_method: "",
        service_bulletin: "",
        amendment_number: "",
        ad_url: "",
        recurring_compliance_method: [],
        next_due_date: 0,
        next_due_hours: 0,
      },

      errors: {},
      ad_record_id: "",
      form_mode: "edit",
    };
  }
  componentDidMount() {
    this.setState({ ad_record_id: this.props.ad_item.id });
    this.storeFormData("", "", "", "", "", "edit");
  }

  //checkbox turning recurring fields on and off
  handleRecurringChange(e) {
    this.setState((prevState) => ({
      form_data: {
        ...prevState.form_data,
        recurring: !prevState.form_data.recurring,
      },
    }));
  }

  deleteFormData(form_data, keys = []) {
    keys.forEach((e) => delete form_data[e]);
    return form_data;
  }

  handleValidation() {
    let fields = this.state.form_data;
    let formIsValid = true;
    let errors = {};
    if (!fields["ad_number"]) {
      formIsValid = false;
      errors["ad_number"] = "Cannot be empty";
    }
    if (!fields["effective_date"]) {
      formIsValid = false;
      errors["effective_date"] = "Cannot be empty";
    }
    if (!fields["mx_area"]) {
      formIsValid = false;
      errors["mx_area"] = "Cannot be empty";
    }
    // if (this.state.recurring) {
    //   const selected_recurring_type =
    //     this.props.recurring_mx_obj[
    //       this.state.form_data.recurring_mx_item_performed[0]
    //     ].units;
    //   switch (selected_recurring_type) {
    //     case "months":
    //       if (!fields["next_due_date"]) {
    //         formIsValid = false;
    //         errors["next_due_date"] = "Cannot be empty";
    //       }
    //       break;
    //     case "hours":
    //       if (!fields["next_due_hours"]) {
    //         formIsValid = false;
    //         errors["next_due_hours"] = "Cannot be empty";
    //       }
    //       break;
    //   }
    // }

    // if (!fields["mx_type"] && fields["mx_area"] != "fuel") {
    //   formIsValid = false;
    //   errors["mx_type"] = "Cannot be empty";
    // }
    // if (!fields["mechanic"] && this.state.form_data.mx_area != "fuel") {
    //   formIsValid = false;
    //   errors["mechanic"] = "Cannot be empty";
    // }
    // if (!fields["inspector"] && this.state.form_data.mx_area != "fuel") {
    //   formIsValid = false;
    //   errors["inspector"] = "Cannot be empty";
    // }
    // if (!fields["work_required"] && this.state.form_data.mx_area != "fuel") {
    //   formIsValid = false;
    //   errors["work_required"] = "Cannot be empty";
    // }
    // if (!fields["work_performed"] && this.state.form_data.mx_area != "fuel") {
    //   formIsValid = false;
    //   errors["work_performed"] = "Cannot be empty";
    // }
    // if (!fields["gallons"] && fields["mx_area"] == "fuel") {
    //   formIsValid = false;
    //   errors["gallons"] = "Cannot be empty";
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }

  storeFormData(
    e = {},
    field = "",
    isArray = false,
    isInt = false,
    recurring_compliance_method_id = "",
    mode = "create"
  ) {
    console.log(mode);
    if (this.state.form_mode == "create" || mode == "create") {
      e.persist();
      let value;
      isInt ? (value = e.target.valueAsNumber) : (value = e.target.value);
      // let aircraft_total_time;
      // let engine_total_time;
      // let tsmoh;
      if (field == "recurring_compliance_method") {
        value = recurring_compliance_method_id;
        let recurring_compliance_method_array = [
          ...this.state.form_data.recurring_compliance_method,
        ];

        if (e.target.checked) {
          recurring_compliance_method_array.push(value);
        }
        if (!e.target.checked) {
          var index = recurring_compliance_method_array.indexOf(value);
          if (index !== -1) {
            recurring_compliance_method_array.splice(index, 1);
          }
        }
        this.setState((prevState) => ({
          form_data: {
            ...prevState.form_data,
            [field]: recurring_compliance_method_array,
          },
        }));
        return;
      }
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          [field]: isArray ? [value] : value,
        },
      }));
      return;
    }
    if (this.state.form_mode == "edit") {
      console.log(this.props.ad_item);
      let keys = Object.keys(this.state.form_data);
      let keysToDelete = [
        "aircraft",
        "aircraft_array",
        "maintenance",
        "na_to_airframe_or_engine",
        "date_of_compliance",
        "tach",
        "inspector",
        "inspection_text",
        "aircraft_details",
      ];

      if (keys.includes("recurring")) {
        if (!this.props.ad_item.recurring ) {
          keysToDelete.push(
            "recurring_interval_hours",
            "recurring_interval_months",
            'next_due_date',
            'next_due_hours'

          );
        }
      }
      console.log(keysToDelete);
      let obj = { ...this.props.ad_item };
      
      let updateFormData = this.deleteFormData(obj, keysToDelete);
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          ...updateFormData,
          //   n_number_string: undefined,
          // id: undefined
        },
      }));
    }
  }
  renderRecurringIntevalType() {
    if (
      this.state.form_data.recurring_compliance_method.indexOf("calendar") >
        -1 &&
      this.state.form_data.recurring_compliance_method.indexOf("hourly") > -1
    ) {
      return (
        <React.Fragment>
          <div className="grid field-container">
            <span className="required">Next Due Date</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="next_due_date"
              type="date"
              value={this.state.form_data.compliance_method}
              onChange={(e) => {
                this.storeFormData(e, "next_due_date", false, false);
              }}
            />
            <span className="error-message">
              {this.state.errors["next_due_date"]}
            </span>
          </div>
          <div className="grid field-container">
            <span className="required">Recurring Interval Months</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="recurring_interval_months"
              type="number"
              value={this.state.form_data.recurring_interval_months}
              onChange={(e) => {
                this.storeFormData(
                  e,
                  "recurring_interval_months",
                  false,
                  true
                );
              }}
            />
            <span className="error-message">
              {this.state.errors["recurring_interval_months"]}
            </span>
          </div>
          <div className="grid field-container">
            <span className="required">Next Due Hours</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="next_due_hours"
              type="number"
              value={this.state.form_data.next_due_hours}
              onChange={(e) => {
                this.storeFormData(e, "next_due_hours", false, true);
              }}
            />
            <span className="error-message">
              {this.state.errors["next_due_hours"]}
            </span>
          </div>
          <div className="grid field-container">
            <span className="required">Recurring Interval Hours</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="recurring_interval_hours"
              type="number"
              value={this.state.form_data.recurring_interval_hours}
              onChange={(e) => {
                this.storeFormData(e, "recurring_interval_hours", false, true);
              }}
            />
            <span className="error-message">
              {this.state.errors["recurring_interval_hours"]}
            </span>
          </div>
        </React.Fragment>
      );
    }
    if (
      this.state.form_data.recurring_compliance_method.indexOf("calendar") > -1
    ) {
      return (
        <React.Fragment>
          <div className="grid field-container">
            <span className="required">Next Due Date</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="next_due_date"
              type="date"
              value={this.state.form_data.next_due_date}
              onChange={(e) => {
                this.storeFormData(e, "next_due_date", false, false);
              }}
            />
            <span className="error-message">
              {this.state.errors["next_due_date"]}
            </span>
          </div>
          <div className="grid field-container">
            <span className="required">Recurring Interval Months</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="recurring_interval_months"
              type="number"
              value={this.state.form_data.recurring_interval_months}
              onChange={(e) => {
                this.storeFormData(
                  e,
                  "recurring_interval_months",
                  false,
                  true
                );
              }}
            />
            <span className="error-message">
              {this.state.errors["recurring_interval_months"]}
            </span>
          </div>
        </React.Fragment>
      );
    }
    if (
      this.state.form_data.recurring_compliance_method.indexOf("hourly") > -1
    ) {
      return (
        <React.Fragment>
          <div className="grid field-container">
            <span className="required">Next Due Hours</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="next_due_hours"
              type="number"
              value={this.state.form_data.next_due_hours}
              onChange={(e) => {
                this.storeFormData(e, "next_due_hours", false, true);
              }}
            />
            <span className="error-message">
              {this.state.errors["next_due_hours"]}
            </span>
          </div>
          <div className="grid field-container">
            <span className="required">Recurring Interval Hours</span>{" "}
            <input
              placeholder="Subject"
              className="form-control"
              name="recurring_interval_hours"
              type="number"
              value={this.state.form_data.recurring_interval_hours}
              onChange={(e) => {
                this.storeFormData(e, "recurring_interval_hours", false, true);
              }}
            />
            <span className="error-message">
              {this.state.errors["recurring_interval_hours"]}
            </span>
          </div>
        </React.Fragment>
      );
    }
  }
  renderRecurringType() {
    if (this.state.form_data.recurring) {
      return (
        <React.Fragment>
          <div className={`grid field-container`}>
            Recurring Interval
            <section>
              <input
                className="form-check-input"
                name="recurring_compliance_method"
                type="checkbox"
                checked={
                  this.state.form_data.recurring_compliance_method.includes(
                    "calendar"
                  )
                    ? true
                    : false
                }
                onChange={(e) => {
                  this.storeFormData(
                    e,
                    "recurring_compliance_method",
                    true,
                    false,
                    "calendar"
                  );
                }}
              />
              <span>&nbsp;Calendar</span>
              <br />

              <input
                className="form-check-input"
                name="recurring_compliance_method"
                type="checkbox"
                checked={
                  this.state.form_data.recurring_compliance_method.includes(
                    "hourly"
                  )
                    ? true
                    : false
                }
                onChange={(e) => {
                  this.storeFormData(
                    e,
                    "recurring_compliance_method",
                    true,
                    false,
                    "hourly"
                  );
                }}
              />
              <span>&nbsp;Hourly</span>
            </section>
          </div>
          {this.renderRecurringIntevalType()}
        </React.Fragment>
      );
    }
  }

  render() {
    const { update_ad_record_status } = this.props;
    const { updateAdRecordById } = this.props.functions;
    return (
      <React.Fragment>
        <section className="entry-details">
          <div className="detail-head create-log-entry grid">
            <div>Entry Information</div>
            <div>Compliance</div>
            <div>Details</div>
            <div>Signatures</div>
          </div>
          <section className="create-log-entry grid">
            <section>
              <div className="grid field-container">
                <span className="required">AD Number</span>{" "}
                <input
                  placeholder="Ad Number"
                  className="form-control"
                  name="ad_number"
                  type="text"
                  value={this.state.form_data.ad_number}
                  onChange={(e) => {
                    this.storeFormData(e, "ad_number", false, false);
                  }}
                />
                <span className="error-message">
                  {this.state.errors["ad_number"]}
                </span>
              </div>
              <div className="grid field-container">
                <span className="required">Category</span>
                <select
                  value={this.state.form_data.mx_area}
                  className="form-control"
                  onChange={(e) => this.storeFormData(e, "mx_area", false)}
                >
                  <option value="">select one</option>
                  <option value="engine">Engine</option>
                  <option value="airframe">Airframe</option>
                  <option value="prop">Propeller</option>
                </select>
              </div>
              <div className="grid field-container">
                <span className="required">Subject</span>{" "}
                <input
                  placeholder="Subject"
                  className="form-control"
                  name="subject"
                  type="text"
                  value={this.state.form_data.subject}
                  onChange={(e) => {
                    this.storeFormData(e, "subject", false, false);
                  }}
                />
                <span className="error-message">
                  {this.state.errors["subject"]}
                </span>
              </div>
              <div className="grid field-container">
                <span className="required">Effective Date</span>{" "}
                <input
                  placeholder="Effective Date"
                  className="form-control"
                  name="effective_date"
                  type="date"
                  value={this.state.form_data.effective_date}
                  onChange={(e) => {
                    this.storeFormData(e, "effective_date", false, false);
                  }}
                />
                <span className="error-message">
                  {this.state.errors["effective_date"]}
                </span>
              </div>
              <div className="grid field-container">
                <span className="required">Amendment Number</span>{" "}
                <input
                  placeholder="Effective Data"
                  className="form-control"
                  name="amendment_number"
                  type="text"
                  value={this.state.form_data.amendment_number}
                  onChange={(e) => {
                    this.storeFormData(e, "amendment_number", false, false);
                  }}
                />
                <span className="error-message">
                  {this.state.errors["amendment_number"]}
                </span>
              </div>
            </section>
            <section>
              <div className="grid field-container">
                <span className="required">Compliance Method</span>{" "}
                <input
                  placeholder="Subject"
                  className="form-control"
                  name="compliance_method"
                  type="text"
                  value={this.state.form_data.compliance_method}
                  onChange={(e) => {
                    this.storeFormData(e, "compliance_method", false, false);
                  }}
                />
                <span className="error-message">
                  {this.state.errors["compliance_method"]}
                </span>
              </div>
              <div className={`grid field-container`}>
                <span className="required">Recurring</span>
                <input
                  className="form-check-input"
                  name="recurring"
                  type="checkbox"
                  checked={this.state.form_data.recurring}
                  onChange={(e) => {
                    this.handleRecurringChange(e);
                  }}
                />
              </div>
              {this.renderRecurringType()}
            </section>
            <section>
              <div className="grid field-container">
                <span className="required">Url</span>{" "}
                <input
                  placeholder="Subject"
                  className="form-control"
                  name="ad_url"
                  type="text"
                  value={this.state.form_data.ad_url}
                  onChange={(e) => {
                    this.storeFormData(e, "ad_url", false, false);
                  }}
                />
                <span className="error-message">
                  {this.state.errors["ad_url"]}
                </span>
              </div>
            </section>
          </section>
          <section className="submit-data">
            <button
              className={`form-control field-container btn btn-primary ld-ext-right ${update_ad_record_status}`}
              onClick={(e) => {
                updateAdRecordById(
                  e,
                  this.state.form_data,
                  this.handleValidation()
                );
              }}
            >
              Update
              <div className="ld ld-ring ld-spin"></div>
            </button>
          </section>
          {update_ad_record_status == "success" ? (
            <div className="submit-data">Record created successfully</div>
          ) : (
            ""
          )}
          {update_ad_record_status == "error" ? (
            <div className="submit-data">
              An error occured. Please try again.
            </div>
          ) : (
            ""
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default AdEdit;
