import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";
import Drawer from "../shared/Drawer";
import { renderComponent } from "recompose";

class Logbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_drawer: false,
      drawer_title: "Mx Record",
    };
    this.handleDrawToggle = this.handleDrawToggle.bind(this);
  }

  // getOptions(data) {
  //   console.log(data);
  //   return data.map((item, index) => {
  //     if (item.id == this.props.data.mechanic[0]) {
  //       return (
  //         <option selected key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     }
  //   });
  // }
  handleDrawToggle(e, action) {
    this.setState((prevState) => ({
      show_drawer: !prevState.show_drawer,
    }));
  }
  render() {
    const {
      date,
      tachometer,
      work_performed,
      id,
      aircraft_details,
      n_number_string,
      aircraft_total_time,
      engine_total_time,
      tsmoh,
      propeller_total_time,
      mx_area,
    } = this.props.data;
    return (
      <React.Fragment>
      <section className="tabular view-log-entries">
        <div onClick={(e) => this.handleDrawToggle(e)}>{date}</div>
        <div
          onClick={(e) => this.handleDrawToggle(e)}
          className="log-entry-data"
        >
          {work_performed}
        </div>
        <div onClick={(e) => this.handleDrawToggle(e)}>{mx_area}</div>
        <div onClick={(e) => this.handleDrawToggle(e)}>
          {tachometer.toFixed(2)}
        </div>
        <div onClick={(e) => this.handleDrawToggle(e)}>
          {aircraft_total_time.toFixed(2)}
        </div>
        <div onClick={(e) => this.handleDrawToggle(e)}>
          {engine_total_time.toFixed(2)}
        </div>
        <div onClick={(e) => this.handleDrawToggle(e)}>{tsmoh.toFixed(2)}</div>
        <div onClick={(e) => this.handleDrawToggle(e)}>
          {propeller_total_time.toFixed(2)}
        </div>
        <div>
          <select
            className="form-control"
            onChange={(e) => {
              //text of select to get n_number string
              const component =
                e.target.options[e.target.selectedIndex].dataset.component;
              if (component == "delete") {
                this.props.handleDeleteMaintenanceRecord(id);
              } else {
                this.props.handleNavigation(e, component, {
                  n_number_string: n_number_string,
                  n_number: e.target.value,
                  ac_details_id: aircraft_details[0],
                  selected_record_id: id,
                  mx_mode: "edit",
                });
              }
            }}
          >
            <option value="">Select Action</option>
            <option data-component="PrintLogEntry" value={this.props}>
              Print
            </option>
            {this.props.user_details.role == "view" ? (
              ""
            ) : (
              <option
                disabled={this.props.user_details.role == "view" ? true : false}
                data-component="maintenance"
                value={""}
              >
                Edit
              </option>
            )}

            {this.props.user_details.role == "view" ? (
              ""
            ) : (
              <option
                disabled={this.props.user_details.role == "view" ? true : false}
                data-component="delete"
                value={""}
              >
                Delete
              </option>
            )}
          </select>
        </div>
        
      </section>
      <section className="drawer-container">
      <Drawer
        state_data={this.state}
        functions={{
          handleDrawToggle: this.handleDrawToggle,
        }}
        mx_item={this.props.data}
        {...this.props}
        content={"mxDetails"}
      />
    </section>
    </React.Fragment>
    );
  }
}
export default Logbook;
