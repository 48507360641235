import React, { Component } from "react";
// import Navigation from "./Navigation";
import { useAuth0, Auth0Context } from "@auth0/auth0-react";
// import { Auth0Context } from "./react-auth0-spa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import LoginButton from "./shared/LoginButton";
import LogoutButton from "./shared/LogoutButton";
import Profile from "./shared/Profile";
class Header extends Component {
  constructor(props) {
    super(props);
  }
  static contextType = Auth0Context;

  render() {
    const { user, isAuthenticated, isLoading } = this.context;
    return (
      <div className="header">
        <nav>
          <ul className="top-nav">
            <li className="top-nav-item" onClick={(e) => {}}>
              <FontAwesomeIcon icon={faHome} />
            </li>
            <li className="top-nav-item">
              Maintenance
              <ul className="secondary-nav">
                <li
                  className="secondary-nav-item"
                  onClick={(e) => {
                    this.props.handleNavigation(e, "", {});
                  }}
                >
                  Dashboard
                </li>
                <li
                  className="secondary-nav-item"
                  onClick={(e) => {
                    this.props.handleNavigation(e, "aircraft", {});
                  }}
                >
                  Aircraft
                </li>
                <li
                  className="secondary-nav-item"
                  onClick={(e) => {
                    this.props.handleNavigation(e, "ad-center", {});
                  }}
                >
                  AD Center
                </li>
                <li
                  className="secondary-nav-item"
                  onClick={(e) => {
                    this.props.handleNavigation(e, "vendors", {});
                  }}
                >
                  Vendors
                </li>
              </ul>
            </li>
            <li
              onClick={(e) => {
                this.props.handleNavigation(e, "document-library", {});
              }}
              className="top-nav-item"
            >
              Documents
            </li>
            <li onClick={(e) => {}} className="top-nav-item">
              Inventory
            </li>
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
            {isAuthenticated ? (
              <li className="top-nav-item">
                <Profile />
              </li>
            ) : (
              ""
            )}
          </ul>
        </nav>
      </div>
    );
  }
}
export default Header;
