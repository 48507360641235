import React, { Component } from "react";
import airtable from "../../utilities/airtable";

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: {
        company: "",
        address: "",
        phone_number: 0,
        notes: "",
      },
      errors: [],
      showVendorForm: false,
      add_new_vendor_record_status: "ready",
      vendor_record: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.form_mode == "edit" &&
      prevProps.vendor_id != this.props.vendor_id
    ) {
      this.getVendorById(this.props.vendor_id).then((resp) => {
        console.log(resp);
        this.storeFormData("", "", "", "", "", "edit");
      });
      // this.setState((prevState) => ({
      //     form_data: {
      //       ...prevState.form_data,
      //     },
      //   }));
    }
  }
  getVendorById(id = "") {
    return airtable
      .getVendorById(id)
      .then((resp) => {
        console.log(resp);
        this.setState({
          vendor_record: resp.fields,
        });
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        // this.logNetworkError(...error);
        console.log("got catch: ", error);
        return Promise.reject({
          request: "mx_by_id",
          error: error,
          status: "processing_error",
        });
      });
  }
  handleValidation() {
    let fields = this.state.form_data;
    let formIsValid = true;
    let errors = {};
    if (!fields["company"]) {
      formIsValid = false;
      errors["company"] = "Cannot be empty";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Cannot be empty";
    }
    if (!fields["phone_number"]) {
      formIsValid = false;
      errors["phone_number"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  storeFormData(
    e = {},
    field = "",
    isArray = false,
    isInt = false,
    mode = "create"
  ) {
    if (this.props.form_mode == "create" || mode == "create") {
      e.persist();
      let value;
      isInt ? (value = e.target.valueAsNumber) : (value = e.target.value);
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          [field]: isArray ? [value] : value,
        },
      }));
      return;
    }
    if (this.props.form_mode == "edit") {
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          ...this.state.vendor_record,
          id: this.props.vendor_id,
        },
      }));
    }
  }
  handleSubmitNewVendorRecord(event, data, isFormValid) {
    console.log(data);
    if (isFormValid) {
      this.setState({ add_new_vendor_record_status: "running" });
      return airtable
        .handleSubmitNewVendorRecord(data)
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              add_new_vendor_record_status: "success",
              vendor_record_updated: resp.records[0].id,
              data_load_ran: false,
              form_data: {
                company: "",
                address: "",
                phone_number: 0,
                notes: "",
              },
            },
            () => {
              this.props.getData();
            }
          );
          // this.getMxById(this.state.selected_n_number);
          // //wait three seconds and then hide the new record form
          // setTimeout(() => {
          //   this.setState({ maintenance_record_status: "ready" });
          // }, 3000);
        })
        .catch((error) => {
          this.setState({
            add_new_vendor_record_status: "error",
          });
          console.log("handleSubmitNewMxRecord: ", error);
        });
    }
  }

  updateVendorRecordById(event, data, isFormValid) {
    console.log(data);
    if (isFormValid) {
      this.setState({ add_new_vendor_record_status: "running" });
      return airtable
        .updateVendorRecordById(data)
        .then((resp) => {
          console.log(resp);
          this.setState(
            {
              add_new_vendor_record_status: "success",
              vendor_record_updated: resp.id,
              data_load_ran: false,
            },
            () => {
              this.props.getData();
            }
          );
          // this.getMxById(this.state.selected_n_number);
          // //wait three seconds and then hide the new record form
          // setTimeout(() => {
          //   this.setState({ maintenance_record_status: "ready" });
          // }, 3000);
        })
        .catch((error) => {
          this.setState({
            add_new_vendor_record_status: "error",
          });
          console.log("handleSubmitNewMxRecord: ", error);
        });
    }
  }
  render() {
    const { add_new_vendor_record_status } = this.state;
    if (this.props.showVendorForm) {
      return (
        <React.Fragment>
          <section className="entry-details">
            <div className="detail-head create-log-entry grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <section className="create-log-entry grid">
              <section>
                <div className="grid field-container">
                  <span className="required">Company</span>{" "}
                  <input
                    className="form-control"
                    required
                    name="vendor_name"
                    type="text"
                    value={this.state.form_data.company}
                    onChange={(e) => this.storeFormData(e, "company")}
                  />
                  <span className="error-message">
                    {this.state.errors["company"]}
                  </span>
                </div>
              </section>
              <section>
                <div className="grid field-container">
                  <span className="required">Address</span>{" "}
                  <input
                    className="form-control"
                    required
                    name="vendor_address"
                    type="text"
                    value={this.state.form_data.address}
                    onChange={(e) => this.storeFormData(e, "address")}
                  />
                  <span className="error-message">
                    {this.state.errors["company"]}
                  </span>
                </div>
              </section>
              <section>
                <div className="grid field-container">
                  <span className="required">Phone Number</span>{" "}
                  <input
                    className="form-control"
                    required
                    name="vendor_phone_number"
                    type="tel"
                    value={this.state.form_data.phone_number}
                    onChange={(e) => this.storeFormData(e, "phone_number")}
                  />
                  <span className="error-message">
                    {this.state.errors["company"]}
                  </span>
                </div>
              </section>
              <section>
                <div className="grid field-container">
                  <span className="">Notes</span>{" "}
                  <input
                    className="form-control"
                    name="vendor_notes"
                    type="text"
                    value={this.state.form_data.notes}
                    onChange={(e) =>
                      this.storeFormData(e, "notes", false, true)
                    }
                  />
                  {/* <span className="error-message">
                    {this.state.errors["notes"]}
                  </span> */}
                </div>
              </section>
            </section>
            <section className="">
              <section className="submit-data">
                <button
                  className={`form-control field-container btn btn-primary ld-ext-right ${add_new_vendor_record_status}`}
                  onClick={(e) => {
                    this.props.form_mode == "create"
                      ? this.handleSubmitNewVendorRecord(
                          e,
                          this.state.form_data,
                          this.handleValidation()
                        )
                      : "";
                    this.props.form_mode == "edit"
                      ? this.updateVendorRecordById(
                          e,
                          this.state.form_data,
                          this.handleValidation()
                        )
                      : "";
                  }}
                >
                  Save
                  <div className="ld ld-ring ld-spin"></div>
                </button>
              </section>
              {add_new_vendor_record_status == "success" ? (
                <div className="submit-data">Record created successfully</div>
              ) : (
                ""
              )}
              {add_new_vendor_record_status == "error" ? (
                <div className="submit-data">
                  An error occured. Please try again.
                </div>
              ) : (
                ""
              )}
            </section>
          </section>
        </React.Fragment>
      );
    } else {
      return false;
    }
  }
}
export default AddVendor;
