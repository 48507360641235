import React from "react";
import { LibraryListItems } from "./LibraryListItems";
import SectionTitle from "../shared/SectionTitle";
// import { useAuth0 } from "@auth0/auth0-react";

export const Library = (props) => {
  console.log(props);
  const documents = props.document_library.map((item, index) => {
    console.log(item.associate_aircraft.length);
    if (props.selected_record_id){
      for (let i = 0; item.associate_aircraft.length > i; i++) {
        if (item.associate_aircraft[i] == props.selected_record_id) {
          return <LibraryListItems key={index} document_library={item} />;
        }
    }
    }
    else if (item.access === "open") {
      return <LibraryListItems key={index} document_library={item} />;
    }
    
  });
  return (
    <section className="component document-library">
      <SectionTitle title="Maintenance Information Center" />
      <div className="">
        <div className="tabular table-head">
          <div>Title</div>
          <div>Document Type</div>
          <div>Manufacturer</div>
          <div>Application</div>
          <div>Description</div>
        </div>
        {documents}
      </div>
    </section>
  );
};
