import React, { Component } from "react";

class FilterControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_aircraft_control: true,
    };
  }
  render() {
    const {
      handleMxTypeFilter,
      handleSearchFilter,
      mx_search_filter,
      aircraft,
    } = this.props;
    const renderACTypes = aircraft.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.n_number}
        </option>
      );
    });
    return (
      <section className="filter-controls">
        <div className="">
          <input
            id="engine"
            className="form-check-input"
            name="engine"
            type="checkbox"
            value={"engine"}
            defaultChecked={true}
            onChange={(e) => handleMxTypeFilter(e)}
          />
          <label className="form-check-label" htmlFor="engine">
            Engine
          </label>
          <input
            id="airframe"
            className="form-check-input"
            name="airframe"
            type="checkbox"
            value={"airframe"}
            defaultChecked={true}
            onChange={(e) => handleMxTypeFilter(e)}
          />
          <label className="form-check-label" htmlFor="airframe">
            Airframe
          </label>
          <input
            id="propeller"
            className="form-check-input"
            name="propeller"
            type="checkbox"
            value={"prop"}
            defaultChecked={true}
            onChange={(e) => handleMxTypeFilter(e)}
          />
          <label className="form-check-label" htmlFor="propeller">
            Propeller
          </label>
          {/*conditionally removed fuel in ad center Ad filter*/}
          {(() => {
            if (!this.props.ad_filters) {
              return (
                <React.Fragment>
                  <input
                    id="fuel"
                    className="form-check-input"
                    name="Fuel"
                    type="checkbox"
                    value={"fuel"}
                    defaultChecked={true}
                    onChange={(e) => handleMxTypeFilter(e)}
                  />
                  <label className="form-check-label" htmlFor="fuel">
                    Fuel
                  </label>
                </React.Fragment>
              );
            }
          })()}
          {/*conditionally add recurring Ad filter*/}
          {(() => {
            if (this.props.ad_filters) {
              return (
                <React.Fragment>
                  <input
                    id="recurring"
                    className="form-check-input"
                    name="Recurring"
                    type="checkbox"
                    value={"recurring"}
                    defaultChecked={true}
                    onChange={(e) => handleMxTypeFilter(e)}
                  />
                  <label className="form-check-label" htmlFor="recurring">
                    Recurring
                  </label>
                </React.Fragment>
              );
            }
          })()}

          {/*conditionally add aircraft filter*/}
          {(() => {
            if (this.state.show_aircraft_control) {
              return (
                <React.Fragment>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      this.props.handleAircraftFilter(e);
                    }}
                  >
                    <option>select aircraft</option>
                    {renderACTypes}
                  </select>
                  <label className="form-check-label" htmlFor="aircraft">
                    Aircraft
                  </label>
                </React.Fragment>
              );
            }
          })()}

          <input
            className="form-control"
            type="text"
            value={mx_search_filter}
            placeholder="Search work performed"
            onChange={(e) => handleSearchFilter(e)}
          />
          {/* <label className='form-control'>
            Search
          </label> */}
          {/* <select
            className="form-control"
            onChange={(e) => {
              // do stuff
            }}
          >
            <option value="">Filter by type</option>
            <option data-component="PrintLogEntry" value={""}>
              Engine
            </option>
            <option data-component="maintenance" value={""}>
              Aircraft
            </option>
            <option data-component="delete" value={""}>
              Propeller
            </option>
          </select> */}
        </div>
      </section>
    );
  }
}
export default FilterControls;
