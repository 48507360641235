import appRequest from "./appRequest";
const airtable = {
  getUserById: (id) => {
    return appRequest
      .getUserById(id)
      .then((resp) => {
        const fields = resp;
        console.log(fields);
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        //console.log(array);
        //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve(array);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "user_by_id", error: error, status: "network_error" });
      });
  },
  
  
  getAllAircraft: (authorizedUser = []) => {
    return appRequest
      .getAllAircraft(authorizedUser)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        //console.log(array);
        //console.log(obj);//
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getAllAircraft", error: error, status: "network_error" });
      });
  },
  getAircraftDetails: () => {
    return appRequest
      .getAircraftDetails()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getAircraftDetails", error: error, status: "network_error" });
      });
  },
  getMxType: () => {
    return appRequest
      .getMxType()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getMxType", error: error, status: "network_error" });
      });
  },
  getVendors: () => {
    return appRequest
      .getVendors()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getVendors", error: error, status: "network_error" });
      });
  },
  handleSubmitNewVendorRecord:(data = {}) => {
    return appRequest
      .createVendorRecord(data)
      .then((resp) => {
        const fields = resp;
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "createVendoreRecord", error: error, status: "network_error" });
      });
  },
  updateVendorRecordById: (data = {}) => {
    // if (data.field == "data") {
    //   //more coming
    // }
    // if (data.field == "mileage") {
    //   data.value = data.value * 1;
    // }
    // if (data.field == "mechanic") {
    //   data.value = [data.value];
    // }
    return appRequest
      .updateVendorRecordById(data)
      .then((resp) => {
        const fields = resp;
        // let obj = {};
        // let array = [];
        // fields.forEach((element) => {
        //   obj[element["id"]] = element["fields"];
        //   element["fields"].id = element["id"];
        //   array.push(element["fields"]);
        // });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "updateVendorRecordById", error: error, status: "network_error" });
      });
  },
  deleteVendorRecord: (data = []) => {
    return appRequest
      .deleteVendorRecord(data)
      .then((resp) => {
        const fields = resp;
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "deleteVendorRecord", error: error, status: "network_error" });
      });
  },
  getVendorById: (id) => {
    return appRequest
      .getVendorById(id)
      .then((resp) => {
        const fields = resp;
        // let obj = {};
        // let array = [];
        // fields.forEach((element) => {
        //   obj[element["id"]] = element["fields"];
        //   element["fields"].id = element["id"];
        //   array.push(element["fields"]);
        // });
        // // //console.log(array);
        // // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "vendor_by_id", error: error, status: "network_error" });
      });
  },
  getPredefinedMxEntries: () => {
    return appRequest
      .getPredefinedMxEntries()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getPredefinedMxEntries", error: error, status: "network_error" });
      });
  },


  getDocumentLibrary: (authorizedUser=[]) => {
    return appRequest
      .getDocumentLibrary(authorizedUser)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getDocumentLibrary", error: error, status: "network_error" });
      });
  },

  getRecurringMX: () => {
    return appRequest
      .getRecurringMX()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getRecurringMX", error: error, status: "network_error" });
      });
  },
  getAllMechanics: () => {
    return appRequest
      .getAllMechanics()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getAllMechanics", error: error, status: "network_error" });
      });
  },
  getAllRecurringTypes: () => {
    return appRequest
      .getAllRecurringTypes()
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getAllRecurringTypes", error: error, status: "network_error" });
      });
  },

  get_ad_by_N_Number: (n_number, type) => {
    return appRequest
      .get_ad_by_N_Number(n_number, type)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getRecordsN_Number", error: error, status: "network_error" });
      });
  },
  getMxN_Number: (n_number, type) => {
    return appRequest
      .getMxN_Number(n_number, type)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id = element["id"];
          array.push(element["fields"]);
        });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve({ responsObj: obj, dataStatus: "ready", responseAry: array });
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "getRecordsN_Number", error: error, status: "network_error" });
      });
  },
  getMxById: (id) => {
    return appRequest
      .getMxById(id)
      .then((resp) => {
        const fields = resp;
        // let obj = {};
        // let array = [];
        // fields.forEach((element) => {
        //   obj[element["id"]] = element["fields"];
        //   element["fields"].id = element["id"];
        //   array.push(element["fields"]);
        // });
        // // //console.log(array);
        // // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "mx_by_id", error: error, status: "network_error" });
      });
  },
  updateAdComplianceMethodById: (data = {}) => {
    return appRequest
      .updateAdComplianceMethodById(data)
      .then((resp) => {
        const fields = resp;
        
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "updateAdComplianceMethod", error: error, status: "network_error" });
      });
  },
  updateAdComplianceMethodByIds: (data = []) => {
    return appRequest
      .updateAdComplianceMethodByIds(data)
      .then((resp) => {
        const fields = resp;
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "updateAdComplianceMethod", error: error, status: "network_error" });
      });
  },
  updateAdRecordById: (data = {}) => {
    // if (data.field == "data") {
    //   //more coming
    // }
    // if (data.field == "mileage") {
    //   data.value = data.value * 1;
    // }
    // if (data.field == "mechanic") {
    //   data.value = [data.value];
    // }
    return appRequest
      .updateAdRecordById(data)
      .then((resp) => {
        const fields = resp;
        // let obj = {};
        // let array = [];
        // fields.forEach((element) => {
        //   obj[element["id"]] = element["fields"];
        //   element["fields"].id = element["id"];
        //   array.push(element["fields"]);
        // });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "updateAdRecordById", error: error, status: "network_error" });
      });
  },
  createAdRecord: (data = {}) => {
    return appRequest
      .createAdRecord(data)
      .then((resp) => {
        const fields = resp;
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "createAdRecord", error: error, status: "network_error" });
      });
  },
  updateMaintenaceRecordById: (data = {}) => {
    // if (data.field == "data") {
    //   //more coming
    // }
    // if (data.field == "mileage") {
    //   data.value = data.value * 1;
    // }
    // if (data.field == "mechanic") {
    //   data.value = [data.value];
    // }
    return appRequest
      .updateMaintenaceRecordById(data)
      .then((resp) => {
        const fields = resp;
        // let obj = {};
        // let array = [];
        // fields.forEach((element) => {
        //   obj[element["id"]] = element["fields"];
        //   element["fields"].id = element["id"];
        //   array.push(element["fields"]);
        // });
        // //console.log(array);
        // //console.log(obj);
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "updateMaintenaceRecordById", error: error, status: "network_error" });
      });
  },
  createMaintenanceRecord: (data = {}) => {
    return appRequest
      .createMaintenanceRecord(data)
      .then((resp) => {
        const fields = resp;
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "createMaintenanceRecord", error: error, status: "network_error" });
      });
  },
  deleteMaintenanceRecord: (data = []) => {
    return appRequest
      .deleteMaintenanceRecord(data)
      .then((resp) => {
        const fields = resp;
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        return Promise.reject({ request: "deleteMaintenanceRecord", error: error, status: "network_error" });
      });
  },
};
export default airtable;
