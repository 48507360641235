import React, { Component } from "react";
import Drawer from "../shared/Drawer";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmarkCircle,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";

class AdList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_drawer: false,
      ad_action_selection: "",
      drawer_title: "Edit AD Record",
      show_details_drawer: false,
      ad_record_id: "",
      valid_ad: false,
    };
    this.handleDrawToggle = this.handleDrawToggle.bind(this);
  }
  componentDidMount() {
    this.setState({ ad_record_id: this.props.data.id });
    this.handleAdIsCompliedWith(
      this.props.data,
      this.props.aircraft_obj,
      this.props.aircraft_details_obj
    );
  }
  componentWillUnmount() {
    this.setState({ ad_record_id: "" });
  }
  handleDetails() {
    this.setState((prevState) => ({
      show_details_drawer: !prevState.show_details_drawer,
    }));
  }
  handleDrawToggle(e, action) {
    this.setState({ ad_action_selection: action });
    switch (action) {
      case "edit":
        this.setState((prevState) => ({
          show_drawer: !prevState.show_drawer,
          ad_action_selection: "",
        }));
        return;
    }
  }
  getInspector(arrayOfIds = []) {
    const ArrayLength = arrayOfIds.length;
    let id = arrayOfIds[ArrayLength - 1];
    // if(arrayOfIds.length>1){
    //   id = arrayOfIds.pop();
    // }
    if (id) {
      return this.props.mechanics_obj[id];
    } else {
      return "not found";
    }
  }
  formatDate(date, dateFormat = "MM/DD/YYYY") {
    const jsDate = new Date(date);
    return <Moment format={dateFormat}>{jsDate}</Moment>;
  }
  renderDataOfComplianceData(data) {
    const recurring = Object.keys(data);
    console.log(this.props.data.date_of_compliance);
    if (this.props.data.date_of_compliance) {
      return (
        <div className="data">
          {this.formatDate(data.date_of_compliance)}
          <br /> {data.tach} tach
        </div>
      );
    } else {
      return <div className="data">Not complied</div>;
    }
  }
  renderNextDueData(data) {
    const recurring = Object.keys(data);
    if (recurring.includes("recurring")) {
      if (data.next_due_date && data.next_due_hours > 0) {
        return (
          <div className="data">
            {this.formatDate(data.next_due_date)}
            <br /> {data.next_due_hours} tach
          </div>
        );
      }
      if (data.next_due_date) {
        return (
          <div className="data">{this.formatDate(data.next_due_date)}</div>
        );
      }
      if (data.next_due_hours > 0) {
        return <div className="data">{data.next_due_hours} tach</div>;
      }
    } else {
      return <div className="data">N/A</div>;
    }
  }
  handleAdIsCompliedWith(data, aircraft, details) {
    if (data.date_of_compliance && data.recurring) {
      //see if recurring ad is expired
      const next_due_date = new Date(data.next_due_date).getTime();
      const today = new Date().getTime();
      console.log(next_due_date, data.ad_number);
      const aircraft_id = data.aircraft[0];
      const aircraft_details_id = aircraft[aircraft_id].aircraft_details[0];
      const current_tach = details[aircraft_details_id].current_tach;
      //if recurring method is both hourly and calendar
      if (
        data.recurring_compliance_method.includes("calendar") &&
        data.recurring_compliance_method.includes("hourly")
      ) {
        console.log(
          "both methods: ",
          data.ad_number,
          "date: ",
          next_due_date > today,
          "tach: ",
          data.tach > current_tach
        );

        //check to see if both calendar and tach are greater than current
        if (next_due_date > today && data.next_due_hours > current_tach) {
          console.log("both methods true: ", data.ad_number);
          this.setState({ valid_ad: true });
          return;
        }
        return;
      }
      //if recurring method is only calendar
      if (
        data.recurring_compliance_method.includes("calendar") &&
        next_due_date
      ) {
        console.log("calendar methods: ", data.ad_number, next_due_date > today, next_due_date, today);
        if (next_due_date > today) {
          this.setState({ valid_ad: true });
          return;
        } else {
          this.setState({ valid_ad: false });
        }
      }
      //if recurring method is only hourly
      if (data.recurring_compliance_method.includes("hourly")) {
        console.log("hourly methods: ", data.ad_number);
        if (data.tach > current_tach) {
          this.setState({ valid_ad: true });
          return;
        } else {
          this.setState({ valid_ad: false });
        }
      }
      return;
    }
    //if it is not recurring and there is a date of compliance
    if (data.date_of_compliance) {
      //do something
      this.setState({ valid_ad: true });
      return;
    }
  }
  render() {
    return (
      <React.Fragment>
        <section className={`ad tabular drawer-container`}>
          <div onClick={(e) => this.handleDetails(e)} className="header-row">
            <div className="icon data">
              {this.state.valid_ad ? (
                <span className="icon success">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              ) : (
                <span className="icon fail">
                  <FontAwesomeIcon icon={faXmarkCircle} />
                </span>
              )}
            </div>

            <div className="data">{this.props.data.ad_number}</div>
            <div className="data">
              {this.formatDate(this.props.data.effective_date)}
            </div>
            <div className="data">
              {this.props.data.subject}{" "}
              {this.props.data.recurring ? "(recurring)" : ""}
            </div>

            <div>
              <select
                className="form-control"
                value={this.state.ad_action_selection}
                onChange={(e) => {
                  //text of select to get n_number string
                  // const component =
                  //   e.target.options[e.target.selectedIndex].dataset.component;
                  // if (component == "delete") {
                  //   this.props.handleDeleteMaintenanceRecord(id);
                  // } else {
                  this.handleDrawToggle(e, e.target.value);
                }}
              >
                <option value="">Select Action</option>
                {this.props.user_details.role == "view" ? (
                  ""
                ) : (
                  <option
                    disabled={
                      this.props.user_details.role == "view" ? true : false
                    }
                    data-component="maintenance"
                    value={"edit"}
                  >
                    Edit
                  </option>
                )}

                {this.props.user_details.role == "view" ? (
                  ""
                ) : (
                  <option
                    disabled={
                      this.props.user_details.role == "view" ? true : false
                    }
                    data-component="delete"
                    value={"delete"}
                  >
                    Delete
                  </option>
                )}
              </select>
            </div>
          </div>
          <section
            className={`details drawer ${
              this.state.show_details_drawer ? "open" : "close"
            }`}
          >
            <div className="compliance-info">
              <div className="subtext">Method of Compliance:</div>
              <div className="data">{this.props.data.compliance_method}{" "} <a target="_blank" rel="noreferrer" className="detail-link" href={this.props.data.ad_url}> (Reference)</a></div>
              
            </div>
            <div className="ad-details">
              <div className="subtext">SB #:</div>
              <div className="data">{this.props.data.service_bulletin}</div>
              <div className="subtext">
                Date: <br />
                Tach:
              </div>
              {this.renderDataOfComplianceData(this.props.data)}
              <div className="subtext">Next Due:</div>
              {this.renderNextDueData(this.props.data)}
            </div>
            <div className="signature">
              <div className="subtext">Signature</div>
              <div className="subtext">Cert #:</div>
              <div className="data">
                {this.getInspector(this.props.data.inspector).name}
                <br />
                {
                  this.getInspector(this.props.data.inspector)
                    .certificate_number
                }
              </div>
            </div>
          </section>
          <div className="drawer-container">
            <Drawer
              state_data={this.state}
              functions={{
                handleDrawToggle: this.handleDrawToggle,
                updateAdRecordById: this.props.updateAdRecordById,
              }}
              ad_item={this.props.data}
              {...this.props}
              content={"AdEdit"}
            />
          </div>
        </section>
        {this.props.index == 2 ||
        this.props.index == 6 ||
        this.props.index == 10 ||
        this.props.index == 14 ||
        this.props.index == 18 ||
        this.props.index == 22 ||
        this.props.index == 26 ||
        this.props.index == 30 ||
        this.props.index == 34 ? (
          <div className="break"></div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
export default AdList;
