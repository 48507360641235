import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";

export const LibraryListItems = (props) => {
  //   const { logout } = useAuth0();
  let { title, type, url, description, manufacture, document_type } = props.document_library;
  if (!description) {
    description = "no discription provided";
  }
  return (
    
    <a href={url} target="_blank" rel="noreferrer">
      <section className={`tabular`}>
        <div>{title}</div>
        <div>{document_type}</div>
        <div>{manufacture}</div>
        <div>{type}</div>
        <div>
          {description.length > 150
            ? description.substring(0, 150) + "..."
            : description}
        </div>
      </section>
    </a>
  );
};
