/* global gapi */

import React, { Component } from "react";
import {
  Routes,
  Route,
  Link,
  withRouter,
  useNavigate,
  Redirect,
} from "react-router-dom";
import {
  Auth0Provider,
  Auth0Context,
  loginWithRedirect,
} from "@auth0/auth0-react";
import Header from "./components/Header";
import airtable from "./utilities/airtable";
import Aircraft from "./components/aircraft_details/Aircraft";
// import Vehicle_Details from "./components/vehicle_details/Vehicle_details";
import Maintenance from "./components/maintenance_logs/Maintenance";
// import AddNewRecord from "./components/maintenance_logs/AddNewRecord";
import Loading from "./components/Loading";
import Logbook from "./components/maintenance_logs/Logbook";
import PrintLogEntry from "./components/maintenance_logs/PrintLogEntry";
import AircraftDetails from "./components/aircraft_details/AircraftDetails";
import FourOFour from "./components/FourOFour";
import Dashboard from "./components/dashboard/Dashboard";
import { Public } from "./components/shared/Public";
import { Library } from "./components/documentation/Library";
import Vendors from "./components/vendor/Vendors";
import AdCenter from "./components/maintenance_logs/AdCenter";
import ADCenter from "./components/maintenance_logs/AdCenter";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_title: "Home",
      status: "loading",
      logBookStatus: "idle",
      aircraft: [],
      aircraft_obj: {},
      vehicle_details_selector: "",
      vehicle_maintenance: [],
      vehicle_maintenance_obj: {},
      add_maintenance_record: false,
      maintenance_record_updated: "",
      add_new_maintenance_record_status: "ready",
      active_component: "aircraft",
      aircraft_details: [],
      aircraft_details_obj: {},
      recurring_mx: [],
      recurring_mx_obj: {},
      error_message: {},
      mx_mode: "create",
      vehicle_maintenance_id: {},
      selected_record_id: "",
      selected_aircraft_details_id: "",
      selected_n_number: "",
      selected_n_number_string: "",
      vehicle_maintenance_sorted_by_tach: [],
      wrapper_class: "",
      user_details: {},
      user_details_loaded: false,
      data_load_ran: false,
      mechanics: [],
      mechanics_obj: {},
      predefined_mx_entries: [],
      predefined_mx_entries_obj: {},
      vendors: [],
      vendors_obj: {},
      document_library: [],
      document_library_obj: {},
      aircraft_ads: [],
      aircraft_ads_obj: {},
      update_ad_compliance_method_record_status: "",
      ad_record_updated: "",
      ad_record_created: "",
      update_ad_record_status: "",
      create_ad_record_status: "",
    };
    // this.handleVehicleSelect = this.handleVehicleSelect.bind(this);
    // this.handleBlur = this.handleBlur.bind(this);
    this.updateMaintenaceRecordById =
      this.updateMaintenaceRecordById.bind(this);
    this.handleSubmitNewMxRecord = this.handleSubmitNewMxRecord.bind(this);
    this.handleDeleteMaintenanceRecord =
      this.handleDeleteMaintenanceRecord.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.getMxN_Number = this.getMxN_Number.bind(this);
    // this.getAircraftDetails = this.getAircraftDetails.bind(this);
    this.getNextItemDue = this.getNextItemDue.bind(this);
    this.getMxById = this.getMxById.bind(this);
    this.getData = this.getData.bind(this);
    this.fourOFourClassMe = this.fourOFourClassMe.bind(this);
    this.get_ad_by_N_Number = this.get_ad_by_N_Number.bind(this);
    this.updateAdComplianceMethodById =
      this.updateAdComplianceMethodById.bind(this);
    this.updateAdComplianceMethodByIds =
      this.updateAdComplianceMethodByIds.bind(this);
    this.updateAdRecordById = this.updateAdRecordById.bind(this);
    this.createAdRecord = this.createAdRecord.bind(this);
  }

  static contextType = Auth0Context;
  componentDidMount() {
    // this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    const { user, isAuthenticated } = this.context;
    if (isAuthenticated && !this.state.user_details_loaded) {
      this.getUserDetails(user.sub, prevState);
    }
    if (!this.state.data_load_ran && this.state.user_details_loaded) {
      this.getData();
    }
  }
  getUserDetails(id, prevState) {
    airtable.getUserById(id).then((resp) => {
      console.log(resp);
      this.setState(
        (prevState) => ({
          ...prevState,
          user_details: {
            user_id: resp[0].auth0_id,
            aircraft_authorized: resp[0].aircraft_authorized,
            role: resp[0].role,
          },
          user_details_loaded: resp.length > 0 ? true : false,
          data_load_ran: resp.length > 0 ? true : false,
        }),
        () => {
          if (this.state.data_load_ran && this.state.user_details_loaded) {
            this.getData();
          }
        }
      );
    });
  }

  getData() {
    const getAllAircraft = airtable
      .getAllAircraft(this.state.user_details.user_id)
      .then((resp) => {
        return { aircraft: resp.responseAry, aircraft_obj: resp.responsObj };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        console.log(error.request, error.status);
        this.logNetworkError(error.request, error.status);
      });
    const getAllMechanics = airtable
      .getAllMechanics()
      .then((resp) => {
        return { mechanics: resp.responseAry, mechanics_obj: resp.responsObj };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        this.logNetworkError(error.request, error.status);
      });
    const getVendors = airtable
      .getVendors()
      .then((resp) => {
        return {
          vendors: resp.responseAry,
          vendors_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        // return (error);
        this.logNetworkError(error.request, error.status);
      });
    const getMxType = airtable
      .getMxType()
      .then((resp) => {
        return {
          mx_types: resp.responseAry,
          mx_types_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        // return (error);
        this.logNetworkError(error.request, error.status);
      });
    const getPredefinedMxEntries = airtable
      .getPredefinedMxEntries()
      .then((resp) => {
        return {
          predefined_mx_entries: resp.responseAry,
          predefined_mx_entries_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        // return (error);
        this.logNetworkError(error.request, error.status);
      });
    const getDocumentLibrary = airtable
      .getDocumentLibrary(this.state.user_details.user_id)
      .then((resp) => {
        return {
          document_library: resp.responseAry,
          document_library_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        // return (error);
        this.logNetworkError(error.request, error.status);
      });
    const getAircraftDetails = airtable
      .getAircraftDetails()
      .then((resp) => {
        return {
          aircraft_details: resp.responseAry,
          aircraft_details_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        // return (error);
        this.logNetworkError(error.request, error.status);
      });
    const getAllRecurringTypes = airtable
      .getAllRecurringTypes()
      .then((resp) => {
        return {
          recurring_mx: resp.responseAry,
          recurring_mx_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        this.logNetworkError(error.request, error.status);
      });
    const getRecurringMX = airtable
      .getRecurringMX()
      .then((resp) => {
        return {
          recurring_mx: resp.responseAry,
          recurring_mx_obj: resp.responsObj,
        };
        //this.setState({ employees: resp.responseAry, employees_obj: resp.responsObj });
      })
      .catch((error) => {
        console.log(error.request, error.status);
        this.logNetworkError(error.request, error.status);
      });

    Promise.all([
      getAllAircraft,
      getAllMechanics,
      getAircraftDetails,
      getRecurringMX,
      getAllRecurringTypes,
      getVendors,
      getPredefinedMxEntries,
      getDocumentLibrary,
      getMxType,
    ])
      .then((responses) => {
        console.log(responses);
        let object = {};
        responses.forEach((item, key, arr) => {
          const keys = Object.keys(item);
          Object.keys(item).forEach((item2) => {
            object[item2] = item[item2];
          });
          if (Object.is(arr.length - 1, key)) {
            // execute last item logic
            console.log(
              `Last callback call at index ${key} with value ${item}`
            );
          }
        });
        //
        this.setState(
          (prevState) => ({ ...prevState, ...object, data_load_ran: true }),
          () => {
            // because set state is batched I'm waiting just a second to start a new setState batch

            setTimeout(() => {
              console.log("STATE after setting state: ", this.state);
              // this.setCurrentUser(urlParams);
              this.setState({ status: "ready" });
            }, 1000);
          }
        );
      })
      .catch((error) => {
        // this.logNetworkError(error.request, error.status);
      });
  }
  logNetworkError(request, error) {
    this.setState((prevState) => ({
      error_message: {
        ...prevState.error_message,
        errors: [`${[request]} - ${error}`],
      },
      status: "error",
    }));
  }
  // getAircraftDetails() {
  //   return airtable
  //     .getAircraftDetails()
  //     .then((resp) => {
  //       console.log(resp);

  //       this.setState({
  //         aircraft_details: resp.responseAry,
  //         aircraft_details_obj: resp.responsObj,
  //         aircraft_details_status: "ready",
  //       });
  //     })
  //     .catch((error) => {
  //       // this.logNetworkError(...error);
  //       console.log("got catch: ", error);
  //     });
  // }
  // getRecurringMX() {
  //   return airtable
  //     .getRecurringMX()
  //     .then((resp) => {
  //       console.log(resp);

  //       this.setState({
  //         recurring_mx: resp.responseAry,
  //         recurring_mx_obj: resp.responsObj,
  //         recurring_mx_status: "ready",
  //       });
  //     })
  //     .catch((error) => {
  //       // this.logNetworkError(...error);
  //       console.log("got catch: ", error);
  //     });
  // }
  getMxN_Number(id = "", type = "n_number") {
    this.setState({ logBookStatus: "loading" });
    return airtable
      .getMxN_Number(id, type)
      .then((resp) => {
        console.log(resp);
        const sortedArryHours = resp.responseAry.sort(function (a, b) {
          return b.tachometer - a.tachometer;
        });

        this.setState({
          vehicle_maintenance_sorted_by_tach: sortedArryHours,
          vehicle_maintenance: resp.responseAry,
          vehicle_maintenance_obj: resp.responsObj,
          logBookStatus: "ready",
        });
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        console.log("got catch: ", error);
        return Promise.reject({
          request: "mx_by_n_number",
          error: error,
          status: "processing_error",
        });

        // this.logNetworkError(...error);
      });
  }

  getMxById(id = "") {
    return airtable
      .getMxById(id)
      .then((resp) => {
        console.log(resp);
        this.setState({
          vehicle_maintenance_id: resp.fields,
        });
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        // this.logNetworkError(...error);
        console.log("got catch: ", error);
        return Promise.reject({
          request: "mx_by_id",
          error: error,
          status: "processing_error",
        });
      });
  }
  get_ad_by_N_Number(id = "", type = "n_number") {
    console.log("got here:", id);
    this.setState({ logBookStatus: "loading" });
    return airtable
      .get_ad_by_N_Number(id, type)
      .then((resp) => {
        console.log(resp);
        // const sortedArryHours = resp.responseAry.sort(function (a, b) {
        //   return b.tachometer - a.tachometer;
        // });

        this.setState({
          //aircraft_ads_sorted_by_tach: sortedArryHours,
          aircraft_ads: resp.responseAry,
          aircraft_ads_obj: resp.responsObj,
          logBookStatus: "ready",
        });
        return new Promise((resolve, reject) => {
          resolve(resp);
        });
      })
      .catch((error) => {
        console.log("got catch: ", error);
        return Promise.reject({
          request: "ad_by_n_number",
          error: error,
          status: "processing_error",
        });

        // this.logNetworkError(...error);
      });
  }

  getNextItemDue(id, n_number) {
    const today = new Date();
    const epoch = today.getTime();
    const current_tach = this.state.aircraft_details_obj[id].current_tach;
    const current_mx_date = this.state.aircraft_details_obj[id].current_mx_date;
    let mostRecentItemByDate = {};
    let mostRecentItemByHours = {};

    return airtable.getMxN_Number(n_number).then((resp) => {
      console.log(resp);
      let filteredArrayDate = [];
      let filteredArrayHours = [];
      const filter = resp.responseAry.map((item, index) => {
        if (item.next_due_date) {
          filteredArrayDate.push(item);
        }
      });
      const filterHours = resp.responseAry.map((item, index) => {
        if (item.next_due_hours) {
          filteredArrayHours.push(item);
        }
      });
      const sortedArryDate = filteredArrayDate.sort(function (a, b) {
        const aDate = new Date(a.next_due_date).getTime();
        const bDate = new Date(b.next_due_date).getTime();
        return aDate - bDate;
      });
      console.log(sortedArryDate);
      const sortedArryHours = filteredArrayHours.sort(function (a, b) {
        return b.tachometer - a.tachometer;
      });

      for (let i = 0; i < sortedArryDate.length; i++) {
        const nextDueEpoch = new Date(
          sortedArryDate[i].next_due_date
        ).getTime();
        if (nextDueEpoch > epoch && sortedArryDate.length > 0) {
          console.log("got here");
          const timeleft = nextDueEpoch - epoch;
          const days = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
          let status;
          days < 30
            ? (status = "warning")
            : days > 60
            ? (status = "ok")
            : (status = "caution");
          mostRecentItemByDate = {
            item_due:
              this.state.recurring_mx_obj[
                sortedArryDate[i].recurring_mx_item_performed
              ].name,
            days_remaining: days,
            status: status,
          };
          console.log('Line 481: ',mostRecentItemByDate);
          
          // return new Promise((resolve, reject) => {
          //   resolve(mostRecentItemByDate);
          // });
          
          
        }
      }

      for (let i = 0; i < sortedArryHours.length; i++) {
        console.log(sortedArryHours);
        const nextHoursDue = sortedArryHours[i].next_due_hours;
        if (sortedArryHours.length > 0) {
          // console.log("got here hours: ", current_tach, nextHoursDue);
          const hours = nextHoursDue - current_tach;
          let status;
          hours < 20
            ? (status = "warning")
            : hours > 30
            ? (status = "ok")
            : (status = "caution");
          mostRecentItemByHours = {
            item_due:
              this.state.recurring_mx_obj[
                sortedArryHours[i].recurring_mx_item_performed
              ].name,
            hours_remaining: hours,
            status: status,
          };
          // console.log("test: ", sortedArryHours);
          break;
        }
      }
      console.log('Line 515: ',mostRecentItemByDate);
      return new Promise((resolve, reject) => {
        
        resolve({ hours: mostRecentItemByHours, dates: mostRecentItemByDate });
      });
      // console.log("got else");
      //     const defaultObj = {
      //       item_due: '--',
      //       days_remaining: '--',
      //       status: 'ok',
      //     };
      //     return new Promise((resolve, reject) => {
      //       resolve(defaultObj);
      //     });
      // for (let i = 0; i < sortedArryHours.length; i++) {
      //   if (sortedArryHours[i].Recurring == 1) {
      //     mostRecentItemByHours.push(sortedArryHours[i].recurring_mx_item_performed[0]);
      //   }
      // }
      // console.log(this.state.recurring_mx_obj[mostRecentItemByHours[0]]);
    });

    //I need to know when the last mx recurring happened and what it was.
    //Then I can loop through the recurring table to see what's next.
  }
  handleNavigation(event = {}, component = "", data = {}) {
    console.log(data);
    const navigate = this.props.navigate;
    navigate(component);
    this.setState(
      {
        active_component: component,
        selected_n_number: data.n_number,
        selected_n_number_string: data.n_number_string,
        selected_aircraft_details_id: data.ac_details_id,
        selected_record_id: data.selected_record_id,
        mx_mode: data.mx_mode,
        wrapper_class: "",
      },
      () => {
        //
      }
    );
  }

  // handleBlur(event, data) {
  //   console.log("event: ", event.target.innerHTML, data);
  //   //reset state
  //   this.setState({
  //     maintenance_record_status: "ready",
  //     maintenance_record_updated: "",
  //   });
  //   let dataObj = {
  //     value: event.target.innerHTML,
  //     id: data.id,
  //     field: data.field,
  //   };
  //   if (dataObj.field == "mechanic") {
  //     dataObj.value = event.target.value;
  //   }
  //   return airtable
  //     .updateMaintenaceRecordById(dataObj)
  //     .then((resp) => {
  //       console.log(resp.id);
  //       this.setState({
  //         maintenance_record_status: "updated",
  //         maintenance_record_updated: resp.id,
  //       });
  //     })
  //     .catch((error) => {
  //       // this.logNetworkError(...error);
  //       console.log("got catch: ", error);
  //     });
  // }
  //this is the toggle function to show the new record form
  // handleAddNewRecord() {
  //   this.setState((prevState) => ({
  //     add_maintenance_record: !prevState.add_maintenance_record,
  //   }));
  // }
  handleSubmitNewMxRecord(event, data, isFormValid) {
    console.log(data);
    if (isFormValid) {
      this.setState({ add_new_maintenance_record_status: "running" });
      return airtable
        .createMaintenanceRecord(data)
        .then((resp) => {
          console.log(resp);
          this.setState({
            add_new_maintenance_record_status: "success",
            maintenance_record_updated: resp.records[0].id,
            data_load_ran: false,
          });
          // this.getMxById(this.state.selected_n_number);
          // //wait three seconds and then hide the new record form
          setTimeout(() => {
            this.setState({ maintenance_record_status: "ready" });
          }, 3000);
        })
        .catch((error) => {
          this.setState({
            add_new_maintenance_record_status: "error",
          });
          console.log("handleSubmitNewMxRecord: ", error);
        });
    }
  }
  updateAdComplianceMethodById(data) {
    console.log(data);
    this.setState({ update_ad_compliance_method_record_status: "running" });
    return airtable
      .updateAdComplianceMethodById(data)
      .then((resp) => {
        console.log(resp);
        this.setState({
          update_ad_compliance_method_record_status: "success",
          ad_record_updated: resp.id,
        });
      })
      .catch((error) => {
        this.setState({
          add_new_maintenance_record_status: "error",
        });
        console.log("handleSubmitNewMxRecord: ", error);
      });
  }
  updateAdComplianceMethodByIds(data) {
    console.log(data);
    this.setState({ update_ad_compliance_method_record_status: "running" });
    return airtable
      .updateAdComplianceMethodByIds(data)
      .then((resp) => {
        console.log(resp);
        this.setState({
          update_ad_compliance_method_record_status: "success",
          ad_record_updated: resp.id,
        });
      })
      .catch((error) => {
        this.setState({
          add_new_maintenance_record_status: "error",
        });
        console.log("handleSubmitNewMxRecord: ", error);
      });
  }
  updateMaintenaceRecordById(event, data, isFormValid) {
    console.log(data);
    if (isFormValid) {
      this.setState({ add_new_maintenance_record_status: "running" });
      return airtable
        .updateMaintenaceRecordById(data)
        .then((resp) => {
          console.log(resp);
          this.setState({
            add_new_maintenance_record_status: "success",
            maintenance_record_updated: resp.id,
            data_load_ran: false,
          });
          // this.getMxById(this.state.selected_n_number);
          // //wait three seconds and then hide the new record form
          setTimeout(() => {
            this.setState({ add_new_maintenance_record_status: "ready" });
          }, 3000);
        })
        .catch((error) => {
          this.setState({
            add_new_maintenance_record_status: "error",
          });
          console.log("handleSubmitNewMxRecord: ", error);
        });
    }
  }
  updateAdRecordById(event, data, isFormValid) {
    this.setState({
      update_ad_record_status: "idle",
    });
    console.log(data);
    if (isFormValid) {
      this.setState({ update_ad_record_status: "running" });
      return airtable
        .updateAdRecordById(data)
        .then((resp) => {
          console.log(resp);
          this.setState({
            update_ad_record_status: "success",
            ad_record_updated: resp.id,
            data_load_ran: false,
          });
          // this.getMxById(this.state.selected_n_number);
          // //wait three seconds and then hide the new record form
          setTimeout(() => {
            this.setState({ update_ad_record_status: "idle" });
          }, 3000);
        })
        .catch((error) => {
          this.setState({
            update_ad_record_status: "error",
          });
          console.log(
            "updateADrecordById: ",
            error.error.response.data.error.message
          );
        });
    }
  }
  createAdRecord(event, data, isFormValid) {
    console.log(data);
    this.setState({ create_ad_record_status: "idle" });
    if (isFormValid) {
      this.setState({ create_ad_record_status: "running" });
      return airtable
        .createAdRecord(data)
        .then((resp) => {
          console.log(resp);
          this.setState({
            create_ad_record_status: "success",
            ad_record_created: resp.id,
          });
          // this.getMxById(this.state.selected_n_number);
          // //wait three seconds and then hide the new record form
          setTimeout(() => {
            this.setState({ create_ad_record_status: "idle" });
          }, 3000);
        })
        .catch((error) => {
          this.setState({
            create_ad_record_status: "error",
          });
          console.log(
            "createAdRecord: ",
            error.error.response.data.error.message
          );
        });
    }
  }

  handleDeleteMaintenanceRecord(data) {
    let check = confirm("Are you sure you want to delete this record");
    if (check) {
      return airtable
        .deleteMaintenanceRecord(data)
        .then((resp) => {
          console.log(resp);
          this.setState({
            logBookStatus: "updated",
          });
          this.getMxN_Number(this.state.selected_n_number_string);
          //wait three seconds and then hide the new record form
          setTimeout(() => {
            this.setState({ logBookStatus: "ready" });
          }, 3000);
        })
        .catch((error) => {
          console.log("got catch: ", error);
        });
    }
  }
  fourOFourClassMe() {
    this.setState({ wrapper_class: "not-found" });
  }
  render() {
    const { user, isAuthenticated, isLoading, loginWithRedirect } =
      this.context;

    let activeComponent;
    // if (this.state.status === "ready") {

    // this.props.navigate("aircraft");
    // switch (this.state.active_component) {
    //   case "aircraft":
    //     activeComponent = (
    //       <Aircraft
    //         aircraft_data={this.state.aircraft}
    //         aircraft_details={this.state.aircraft_details_obj}
    //         status={this.state.status}
    //         getNextItemDue={this.getNextItemDue}
    //         handleNavigation={this.handleNavigation}
    //         getData={this.getData}
    //       />
    //     );
    //     break;
    //   case "maintenance":
    //     activeComponent = (
    //       <Maintenance
    //         {...this.state}
    //         handleSubmitNewMxRecord={this.handleSubmitNewMxRecord}
    //         updateMaintenaceRecordById={this.updateMaintenaceRecordById}
    //         getMxN_Number={this.getMxN_Number}
    //         getMxById={this.getMxById}
    //       />
    //     );
    //     break;
    //   case "logbook":
    //     activeComponent = (
    //       <Logbook
    //         {...this.state}
    //         // handleSubmitNewMxRecord={this.handleSubmitNewMxRecord}
    //         getMxN_Number={this.getMxN_Number}
    //         handleNavigation={this.handleNavigation}
    //         handleDeleteMaintenanceRecord={this.handleDeleteMaintenanceRecord}
    //       />
    //     );
    //     break;
    //   case "PrintLogEntry":
    //     activeComponent = <PrintLogEntry {...this.state} />;
    // }
    if (isAuthenticated) {
      if (this.state.status === "ready") {
        return (
          <React.Fragment>
            <Header
              page_title={this.state.page_title}
              handleNavigation={this.handleNavigation}
            />
            <section className={`data-container ${this.state.wrapper_class}`}>
              <Routes>
                <Route
                  path="*"
                  element={
                    <FourOFour fourOFourClassMe={this.fourOFourClassMe} />
                  }
                />
                <Route
                  path="/"
                  element={
                    <Aircraft
                      aircraft_data={this.state.aircraft}
                      user_details={this.state.user_details}
                      aircraft_details={this.state.aircraft_details_obj}
                      status={this.state.status}
                      getNextItemDue={this.getNextItemDue}
                      handleNavigation={this.handleNavigation}
                      getData={this.getData}
                    />
                    // <ADCenter
                    //   {...this.state}
                    //   getData={this.getData}
                    //   get_ad_by_N_Number={this.get_ad_by_N_Number}
                    //   updateAdRecordById={this.updateAdRecordById}
                    //   createAdRecord={this.createAdRecord}
                    // />
                  }
                />
                <Route
                  path="/aircraft"
                  element={
                    <Aircraft
                      aircraft_data={this.state.aircraft}
                      user_details={this.state.user_details}
                      aircraft_details={this.state.aircraft_details_obj}
                      status={this.state.status}
                      getNextItemDue={this.getNextItemDue}
                      handleNavigation={this.handleNavigation}
                      getData={this.getData}
                    />
                  }
                />
                <Route
                  path="/maintenance"
                  element={
                    <Maintenance
                      {...this.state}
                      handleSubmitNewMxRecord={this.handleSubmitNewMxRecord}
                      updateMaintenaceRecordById={
                        this.updateMaintenaceRecordById
                      }
                      getMxN_Number={this.getMxN_Number}
                      getMxById={this.getMxById}
                      get_ad_by_N_Number={this.get_ad_by_N_Number}
                      updateAdComplianceMethodById={
                        this.updateAdComplianceMethodById
                      }
                      updateAdComplianceMethodByIds={
                        this.updateAdComplianceMethodByIds
                      }
                    />
                  }
                />
                <Route
                  path="/logbook"
                  element={
                    <Logbook
                      {...this.state}
                      // handleSubmitNewMxRecord={this.handleSubmitNewMxRecord}
                      getMxN_Number={this.getMxN_Number}
                      handleNavigation={this.handleNavigation}
                      handleDeleteMaintenanceRecord={
                        this.handleDeleteMaintenanceRecord
                      }
                    />
                  }
                />
                <Route
                  path="/aircraft-details"
                  element={
                    <AircraftDetails
                      {...this.state}
                      // handleSubmitNewMxRecord={this.handleSubmitNewMxRecord}
                      handleNavigation={this.handleNavigation}
                    />
                  }
                />
                <Route
                  path="/document-library"
                  element={
                    <Library
                      {...this.state}
                      // handleSubmitNewMxRecord={this.handleSubmitNewMxRecord}
                      handleNavigation={this.handleNavigation}
                    />
                  }
                />
                <Route
                  path="/PrintLogEntry"
                  element={<PrintLogEntry {...this.state} />}
                />
                <Route
                  path="/vendors"
                  element={<Vendors {...this.state} getData={this.getData} />}
                />

                <Route
                  path="/ad-center"
                  element={
                    <AdCenter
                      {...this.state}
                      getData={this.getData}
                      get_ad_by_N_Number={this.get_ad_by_N_Number}
                      updateAdRecordById={this.updateAdRecordById}
                      createAdRecord={this.createAdRecord}
                    />
                  }
                />
              </Routes>
            </section>

            {/* <section className="maintenace-details"></section> */}
          </React.Fragment>
        );
      } else if (this.state.status === "error") {
        return (
          <div className="data-container">
            <div className="component">
              An error occured. Please try again. Error Message:{" "}
              {this.state.error_message.errors[0]}
            </div>
          </div>
        );
      } else {
        return <Loading componentClass={"data-container"} />;
      }
    } else {
      return (
        <React.Fragment>
          <Header
            page_title={this.state.page_title}
            handleNavigation={this.handleNavigation}
          />
          <section className="data-container">
            <Public />
          </section>
        </React.Fragment>
      );
    }
  }
}
function WithNavigate(props) {
  let navigate = useNavigate();

  return <App {...props} navigate={navigate} />;
}
export default WithNavigate;
