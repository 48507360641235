import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";

export const Public = () => {
  //   const { logout } = useAuth0();

  return (
    <section className="landing-page-public">
      <section>
        <h1>Maintenance Logs</h1>
        <p>sign in to begin</p>
      </section>
    </section>
  );
};
