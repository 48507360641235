import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";
import LogbookEntries from "./LogbookEntries";
import FilterControls from "./FilterControls";
import Loading from "../Loading";

class Logbook extends Component {
  constructor(props) {
    super(props);
    this.handleMxTypeFilter = this.handleMxTypeFilter.bind(this);
    this.handleSearchFilter = this.handleSearchFilter.bind(this);
    this.state = {
      vehicle_maintenance: [],
      mx_type_filter: ["engine", "airframe", "prop", "fuel"],
      mx_search_filter: "",
    };
  }
  componentDidMount() {
    //get mx items
    this.props
      .getMxN_Number(this.props.selected_n_number_string)
      .then((resp) => {
        console.log(this.props.vehicle_maintenance);
        this.setState((prevState) => ({
          vehicle_maintenance: this.props.vehicle_maintenance,
        }));
        console.log(resp);
      });
  }
  componentDidUpdate(prevProps) {
    const { vehicle_maintenance_obj } = this.props;
    if (prevProps.vehicle_maintenance_obj != vehicle_maintenance_obj) {
      this.setState((prevState) => ({
        vehicle_maintenance: this.props.vehicle_maintenance,
      }));
    }
  }
  handleMxTypeFilter(e) {
    e.persist();
    console.log(e);
    if (e.target.checked) {
      this.setState((prevState) => ({
        mx_type_filter: [...prevState.mx_type_filter, e.target.value],
      }));
    }
    if (!e.target.checked) {
      const elements = this.state.mx_type_filter;
      for (let i = 0; i < elements.length; i++) {
        if (elements[i] == e.target.value) {
          elements.splice(i, 1);
        }
      }
      this.setState({ mx_type_filter: elements });
    }
    //do stuff
  }
  handleSearchFilter(e) {
    e.persist();
    this.setState((prevState) => ({
      mx_search_filter: e.target.value,
    }));
  }

  // getOptions(data) {
  //   console.log(data);
  //   return data.map((item, index) => {
  //     if (item.id == this.props.data.mechanic[0]) {
  //       return (
  //         <option selected key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     }
  //   });
  // }

  render() {
    if (
      this.props.logBookStatus == "ready" ||
      this.props.logBookStatus == "idle"
    ) {
      const { vehicle_maintenance } = this.state;
      let filtered_mx_items = [];
      let filter = vehicle_maintenance.filter((mx) => {
        const elements = this.state.mx_type_filter;
        const text = this.state.mx_search_filter;
        console.log(elements);
        for (let i = 0; i < elements.length; i++) {
          console.log(mx.mx_area);
          if (mx.work_performed) {
            if (
              mx.mx_area == elements[i] &&
              mx.work_performed.toLowerCase().includes(text, 0)
            ) {
              filtered_mx_items.push(mx);
            }
          } else {
            if (
              mx.mx_area == elements[i] &&
              mx.work_required.toLowerCase().includes(text, 0)
            ) {
              filtered_mx_items.push(mx);
            }
          }
        }
      });
      let mx_items = filtered_mx_items.map((item, index) => {
        return (
          <LogbookEntries
            key={index}
            data={item}
            user_details={this.props.user_details}
            handleNavigation={this.props.handleNavigation}
            handleDeleteMaintenanceRecord={
              this.props.handleDeleteMaintenanceRecord
            }
            {...this.props}
          />
        );
      });
      return (
        <section className="component logbook">
          <SectionTitle title="Logbook / List View" />
          <FilterControls
            data={vehicle_maintenance}
            handleMxTypeFilter={this.handleMxTypeFilter}
            handleSearchFilter={this.handleSearchFilter}
            ad_filters={false}
            {...this.state}
            {...this.props}
          />
          <section className="entry-details">
            <div className="table-head view-log-entries tabular">
              <div>Date</div>
              <div>Work Performed</div>
              <div>MX Area</div>
              <div>Tach</div>
              <div>AC Total Time</div>
              <div>Engine Total Time</div>
              <div>TSMOH</div>
              <div>Prop Total Time</div>
              <div>Action</div>
            </div>
          </section>
          {mx_items}
        </section>
      );
    } else {
      return <Loading componentClass={"data-container"} />;
    }
  }
}
export default Logbook;
