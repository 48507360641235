import App from "../src/App";
import React, { Component } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import WithNavigate from "../src/App";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faCircle } from "@fortawesome/free-solid-svg-icons";


library.add(fas, faCircle);

ReactDOM.render(
  <Auth0Provider
    domain="aviation-mx.us.auth0.com"
    clientId="7vXoPA33IE0peJvn5yJAR6PDTl2XRhwT"
    redirectUri={window.location.origin}
  >
    <BrowserRouter>
      <WithNavigate />
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
