import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";

class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recurring: false,
      has_ads: false,
      selected_recurring_mx: "",
      selected_recurring_type: "",
      render_next_due_type: false,
      form_data: {
        ads: [],
        n_number: "",
        aircraft_details: [],
        date: "",
        mx_area: "",
        tachometer: 0,
        vendor: "",
        mechanic: "",
        inspector: "",
        aircraft_total_time: "",
        engine_total_time: "",
        propeller_total_time: "",
        tsmoh: "",
        work_required: "",
        work_performed: "",
        recurring: false,
        recurring_mx_item_performed: [],
        mx_type: "",
        has_ads: false,
      },
      record_closest_to_entered_tach: {},
      submit_status: "idle",
      errors: {},
      ad_compliance_method_status: "idle",
      ad_compliance_button_index: null,
      edit_ads: false,
      ad_edit_id: "",
      ad_message: "",
      ad_form_data: {},
      ad_compliance_recurring_status: "idle",
    };
  }
  componentDidMount() {
    //get mx items for calculating total time fields
    if (this.props.mx_mode == "create") {
      this.props.getMxN_Number(this.props.selected_n_number_string);
      this.props.get_ad_by_N_Number(this.props.selected_n_number_string);
    }
    if (this.props.mx_mode == "edit") {
      this.props.getMxById(this.props.selected_record_id).then((resp) => {
        console.log(resp);
        this.props.get_ad_by_N_Number(this.props.selected_n_number_string);
        this.storeFormData("", "", "", "", "", "edit");
      });
    }
    // const mxSortedList = this.sortMxItems(this.props.vehicle_maintenance);
    //set the n_number from props and get mx records
    this.setState((prevState) => ({
      form_data: {
        ...prevState.form_data,
        n_number: [this.props.selected_n_number],
      },
    }));
  }
  handleAdText(e, inspection_text) {
    const prefix =
      "VERIFIED AD COMPLIANCE AND COMPLETED RECURRING ADS AS FOLLOWS:";
    e.persist();
    if (e.target.checked) {
      this.setState((prevState) => {
        if (this.state.form_data.ads.length < 1) {
          return {
            form_data: {
              ...prevState.form_data,
              work_performed: prefix + inspection_text,
            },
          };
        } else {
          return {
            form_data: {
              ...prevState.form_data,
              work_performed:
                this.state.form_data.work_performed + inspection_text,
            },
          };
        }
      });
    }
    if (!e.target.checked) {
      const string = this.state.form_data.work_performed;
      let newString = string.replace(inspection_text, "");
      if (this.state.form_data.ads.length <= 1) {
        console.log("got here: ", newString);
        newString = newString.replace(prefix, "");
      }
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          work_performed: newString,
        },
      }));
    }
  }
  handelAdEdit(e, id) {
    e.persist();
    e.preventDefault();
    this.setState((prevState) => ({
      edit_ads: !prevState.edit_ads,
      ad_edit_id: id,
    }));
  }
  showEdit(item, index) {
    if (this.state.ad_form_data.hasOwnProperty(item.id)) {
      // console.log("line 118: ", this.state.ad_form_data[item.id], item.id);
      if (this.state.ad_form_data.hasOwnProperty(item.id)) {
        console.log("line 119: ");
        return (
          <span>
            <a
              href=""
              onClick={(e) => {
                this.handelAdEdit(e, index);
              }}
            >
              Edit
            </a>
          </span>
        );
      }
    } else {
      return "";
    }
  }
  renderAds() {
    const data = this.props.aircraft_ads;
    if (this.state.form_data.has_ads) {
      return data.map((item, index) => {
        //check if it is complied with and not recurring
        let keys = Object.keys(item);
        if (!item.date_of_compliance || keys.includes("recurring")) {
          return (
            <section key={index}>
              <input
                className="form-check-input"
                name="ads"
                type="checkbox"
                checked={
                  this.state.form_data.ads.includes(item.id) ? true : false
                }
                onChange={(e) => {
                  this.storeFormData(e, "ads", true, false, item.id);
                  this.handleAdText(e, item.inspection_text);
                  this.storeAdComplianceMethodToState(
                    e,
                    item.id,
                    item,
                    "next-due-update"
                  );
                }}
              />
              <span>
                {item.subject} | {item.ad_number} | {item.compliance_method}{" "}
                {item.recurring
                  ? "| Next Due:  " +
                    item.next_due_date +
                    ", " +
                    item.next_due_hours
                  : ""}
              </span>
              {this.showEdit(item, index)}

              {(() => {
                if (this.state.edit_ads && this.state.ad_edit_id == index) {
                  return (
                    <div className="edit">
                      <input
                        className="form-control"
                        name="compliance-method"
                        type="text"
                        onChange={(e) => {
                          this.storeAdComplianceMethodToState(
                            e,
                            item.id,
                            item,
                            "text-update"
                          );
                        }}
                      ></input>
                      <button
                        className={`form-control field-container btn btn-primary ld-ext-right ${
                          index == this.state.ad_compliance_button_index
                            ? this.state.ad_compliance_method_status
                            : "idle"
                        } `}
                        onClick={(e) => {
                          this.storeAdComplianceMethod(e, item, index);
                        }}
                      >
                        Save
                        <i className="ld ld-ring ld-spin"></i>
                      </button>
                    </div>
                  );
                }
              })()}
            </section>
          );
        }
      });
    }
    return;
  }
  renderRecurringType(recurringMxOptions) {
    if (this.state.form_data.recurring) {
      return (
        <React.Fragment>
          <div className="grid field-container">
            <span>Type</span>
            <select
              className="form-control"
              value={this.state.form_data.recurring_mx_item_performed[0]}
              onChange={(e) => {
                // this.handleRecurringTypeChange(e);
                this.storeFormData(
                  e,
                  "recurring_mx_item_performed",
                  true,
                  false,
                  "create"
                );
              }}
            >
              <option value="not_set">select one</option>
              {recurringMxOptions}
            </select>
          </div>
          {this.state.form_data.recurring_mx_item_performed.length > 0
            ? this.renderNextDueType()
            : ""}
        </React.Fragment>
      );
    }
    return;
  }
  renderNextDueType() {
    const { recurring_mx_item_performed } = this.state.form_data;
    const recurring_item = this.props.recurring_mx_obj[
      recurring_mx_item_performed[0]
    ] || { units: "not_set" };
    console.log(recurring_item);
    if (recurring_item.units === "months") {
      // const mockEvent =  { target: { value: undefined },persist:()=>{} };
      // this.storeFormData(mockEvent, "next_due_date");
      // this.setState({selected_recurring_type: "months"});
      return (
        <div className="grid field-container">
          <span>Date Due</span>
          <input
            className="form-control"
            required
            name="next_due_date"
            type="date"
            value={this.state.form_data.next_due_date || ""}
            onChange={(e) =>
              this.storeFormData(e, "next_due_date", false, false, "create")
            }
          />
          <span className="error-message">
            {this.state.errors["next_due_date"]}
          </span>
        </div>
      );
    }
    if (recurring_item.units === "hours") {
      // this.setState({selected_recurring_type: "hours"});
      return (
        <div className="grid field-container">
          <span>Hours Due</span>
          <input
            className="form-control "
            name="next_due_hours"
            type="number"
            value={
              isNaN(this.state.form_data.next_due_hours)
                ? ""
                : this.state.form_data.next_due_hours
            }
            onChange={(e) =>
              this.storeFormData(e, "next_due_hours", false, true, "create")
            }
          />
          <span className="error-message">
            {this.state.errors["next_due_hours"]}
          </span>
        </div>
      );
    }
    return;
  }
  handlePredefinedText(e) {
    e.persist();
    this.setState((prevState) => ({
      form_data: {
        ...prevState.form_data,
        work_performed: e.target.value,
      },
    }));
  }

  //save compliance method to state for later sending to db.
  //TODO:  handle how to save next due data.  Can't happen on update of compliance method.
  storeAdComplianceMethodToState(e, id, item, update_type = "text-update") {
    e.persist(e);
    console.log(e);
    let next_due_date = "";
    let next_due_hours = "";
    let data = {};
    //check to see if the airtable object has recurring method
    if (item.hasOwnProperty("recurring_compliance_method")) {
      if (item.recurring_compliance_method.indexOf("calendar") >= 0) {
        let date = new Date(this.state.form_data.date);
        date = new Date(
          date.setMonth(date.getMonth() + item.recurring_interval_months)
        );
        date = date.toISOString().slice(0, 10);
        next_due_date = date;
        console.log(next_due_date);
      }
      if (item.recurring_compliance_method.indexOf("hourly") >= 0) {
        next_due_hours =
          this.state.form_data.tachometer + item.recurring_interval_hours;
        console.log(next_due_hours);
      }
    }
    if (update_type == "text-update") {
      this.setState((prevState) => ({
        ad_form_data: {
          ...prevState.ad_form_data,
          [id]: {
            ...prevState.ad_form_data[id],
            compliance_method: e.target.value,
          },
        },
      }));
    }
    if (update_type == "next-due-update") {
      if (!e.target.checked) {
        const copy = this.state.ad_form_data;
        delete copy[id];
        this.setState((prevState) => ({
          ...prevState.ad_form_data,
          ad_form_data: copy,
          edit_ads: false,
        }));
      } else if (this.props.aircraft_ads_obj[id].recurring) {
        this.setState((prevState) => ({
          ad_form_data: {
            ...prevState.ad_form_data,
            [id]: {
              next_due_date,
              next_due_hours: next_due_hours * 1,
            },
          },
        }));
      } else {
        this.setState((prevState) => ({
          ad_form_data: {
            ...prevState.ad_form_data,
            [id]: {},
          },
        }));
      }
    }
    console.log(data);
  }
  storeAdComplianceMethod(e, item = {}, index = 0, update_type = "") {
    if (this.state.has_ads) {
      this.setState({
        ad_compliance_method_status: "running",
        ad_compliance_button_index: index,
      });
      e.persist(e);
      let data;
      if (update_type == "next-due-update") {
        data = [];
        const obj = this.state.ad_form_data;
        console.log(obj);
        // //remove id from data object for writing to airtable
        // delete obj['id'];
        Object.getOwnPropertyNames(obj).forEach((key, value) => {
          console.log(`test-- ${key}:${obj[key][key]}`);
          if (obj[key] != "id") {
            data.push({ id: key, fields: obj[key] });
          }
        });
        // delete data['id'];
        // for(let i=0; data.length <= i; i++){

        // }
        console.log(data);
        this.props
          .updateAdComplianceMethodByIds(data)
          .then((resp) => {
            this.setState({ ad_compliance_recurring_status: "updated" });
            this.props.get_ad_by_N_Number(this.props.selected_n_number_string);
          })
          .catch((resp) => {
            this.setState({ ad_compliance_recurring_status: "error" });
          });
        setTimeout(() => {
          this.setState({ ad_compliance_recurring_status: "idle" });
        }, 3000);

        //   data = [
        //     id: [this.state.ad_form_data[item.id].id],
        //     next_due_date: this.state.ad_form_data[item.id].next_due_date,
        //     next_due_hours: this.state.ad_form_data[item.id].next_due_hours,
        //   ;
        // }
        // ...this.state[item.id],
        // id: item.id,
      } else {
        data = {
          id: [Object.keys(this.state.ad_form_data)],
          compliance_method: this.state.ad_form_data[item.id].compliance_method,
        };

        this.props
          .updateAdComplianceMethodById(data)
          .then((resp) => {
            this.setState({ ad_compliance_method_status: "updated" });
            this.props.get_ad_by_N_Number(this.props.selected_n_number_string);
          })
          .catch((resp) => {
            this.setState({ ad_compliance_method_status: "error" });
          });
        setTimeout(() => {
          this.setState({ ad_compliance_method_status: "idle" });
        }, 3000);
      }
    }
  }

  //checkbox turning recurring fields on and off
  handleRecurringChange(e) {
    console.log(e);
    this.setState((prevState) => ({
      form_data: {
        ...prevState.form_data,
        recurring: !prevState.recurring,
      },
      recurring: !prevState.recurring,
    }));
  }

  //checkbox turning ad fields on and off
  handleAdsChange(e) {
    console.log(e);
    if (this.props.mx_mode == "edit") {
      this.setState({
        ad_message:
          "You cannot edit ad notes when editing a record.  Please use the AD center.",
      });
      return;
    }

    if (this.state.form_data.date && this.state.form_data.tachometer) {
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          has_ads: !prevState.has_ads,
        },
        has_ads: !prevState.has_ads,
        ad_message: "",
      }));
    } else {
      e.preventDefault();
      this.setState({
        ad_message: "Please select date and tach before continuing.  ",
      });
    }
  }
  // store the selected mx record id in state and toggle the next due fields
  handleRecurringTypeChange(e) {
    this.setState((prevState) => ({
      form_data: {
        ...prevState.form_data,
        recurring_mx_item_performed: [e.target.value],
      },
      render_next_due_type: true,
    }));
    // this.setState({ selected_recurring_mx: e.target.value,  });
  }
  closestTach(array, value) {
    const result = array.reduce(function (r, a, i, aa) {
      if (
        i &&
        Math.abs(aa[r].tachometer - value) <= Math.abs(a.tachometer - value)
      ) {
        if (aa[r].tachometer <= value) {
          return r;
        } else {
          return i;
        }
      } else {
        return i;
      }
    }, -1);

    return result;
    // return Math.max.apply(null, arr.filter(function(v){return v <= val}))
  }
  calculateTimes(field = "", value) {
    //check to see if there is pervious vehilce maintenance first
    if (this.props.vehicle_maintenance_sorted_by_tach.length < 1) {
      switch (field) {
        case "ac_total_time":
          return value * 1;
        case "engine_total_time":
          return value * 1;
        case "tsmoh":
          return value * 1;
        case "propeller_total_time":
          return value * 1;
        default:
          return value * 1;
      }
    }
    const data = this.closestTach(
      this.props.vehicle_maintenance_sorted_by_tach,
      value
    );
    const previous =
      this.props.vehicle_maintenance_sorted_by_tach[data].tachometer;
    const difference = value - previous;
    switch (field) {
      case "ac_total_time":
        return (
          difference +
          this.props.vehicle_maintenance_sorted_by_tach[data]
            .aircraft_total_time
        );
      case "engine_total_time":
        return (
          difference +
          this.props.vehicle_maintenance_sorted_by_tach[data].engine_total_time
        );
      case "tsmoh":
        return (
          difference + this.props.vehicle_maintenance_sorted_by_tach[data].tsmoh
        );
      case "propeller_total_time":
        return (
          difference +
          this.props.vehicle_maintenance_sorted_by_tach[data]
            .propeller_total_time
        );
      default:
        return data;
    }
  }
  handleValidation() {
    let fields = this.state.form_data;
    let formIsValid = true;
    let errors = {};
    if (!fields["tachometer"]) {
      formIsValid = false;
      errors["tachometer"] = "Cannot be empty";
    }
    if (!fields["date"]) {
      formIsValid = false;
      errors["date"] = "Cannot be empty";
    }
    if (!fields["mx_area"]) {
      formIsValid = false;
      errors["mx_area"] = "Cannot be empty";
    }
    if (this.state.recurring) {
      const selected_recurring_type =
        this.props.recurring_mx_obj[
          this.state.form_data.recurring_mx_item_performed[0]
        ].units;
      switch (selected_recurring_type) {
        case "months":
          if (!fields["next_due_date"]) {
            formIsValid = false;
            errors["next_due_date"] = "Cannot be empty";
          }
          break;
        case "hours":
          if (!fields["next_due_hours"]) {
            formIsValid = false;
            errors["next_due_hours"] = "Cannot be empty";
          }
          break;
      }
    }
    if (!fields["mx_area"]) {
      formIsValid = false;
      errors["mx_area"] = "Cannot be empty";
    }
    if (!fields["mx_type"] && fields["mx_area"] != "fuel") {
      formIsValid = false;
      errors["mx_type"] = "Cannot be empty";
    }
    if (!fields["mechanic"] && this.state.form_data.mx_area != "fuel") {
      formIsValid = false;
      errors["mechanic"] = "Cannot be empty";
    }
    if (!fields["inspector"] && this.state.form_data.mx_area != "fuel") {
      formIsValid = false;
      errors["inspector"] = "Cannot be empty";
    }
    if (!fields["work_required"] && this.state.form_data.mx_area != "fuel") {
      formIsValid = false;
      errors["work_required"] = "Cannot be empty";
    }
    // if (!fields["work_performed"] && this.state.form_data.mx_area != "fuel") {
    //   formIsValid = false;
    //   errors["work_performed"] = "Cannot be empty";
    // }
    if (!fields["gallons"] && fields["mx_area"] == "fuel") {
      formIsValid = false;
      errors["gallons"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  storeFormData(
    e = {},
    field = "",
    isArray = false,
    isInt = false,
    ad_id = "",
    mode = "create"
  ) {
    console.log(mode);
    if (this.props.mx_mode == "create" || mode == "create") {
      e.persist();
      let value;
      isInt ? (value = e.target.valueAsNumber) : (value = e.target.value);
      // let aircraft_total_time;
      // let engine_total_time;
      // let tsmoh;
      if (field == "tachometer") {
        const data = this.calculateTimes("", e.target.value);
        this.setState((prevState) => ({
          form_data: {
            ...prevState.form_data,
            aircraft_total_time: this.calculateTimes(
              "ac_total_time",
              e.target.value
            ),
            engine_total_time: this.calculateTimes(
              "engine_total_time",
              e.target.value
            ),
            propeller_total_time: this.calculateTimes(
              "propeller_total_time",
              e.target.value
            ),
            tsmoh: this.calculateTimes("tsmoh", e.target.value),
          },
          record_closest_to_entered_tach:
            this.props.vehicle_maintenance_sorted_by_tach.length > 0
              ? this.props.vehicle_maintenance_sorted_by_tach[data]
              : value,
        }));
      }
      if (field == "vendor" && e.target.value == "add_vendor") {
        window.open("https://aviation.simonation.com/vendors", "_blank");
      }
      if (field == "ads") {
        value = ad_id;
        let ads_array = [...this.state.form_data.ads];

        if (e.target.checked) {
          ads_array.push(value);
        }
        if (!e.target.checked) {
          var index = ads_array.indexOf(value);
          if (index !== -1) {
            ads_array.splice(index, 1);
          }
        }
        this.setState((prevState) => ({
          form_data: {
            ...prevState.form_data,
            [field]: ads_array,
          },
        }));
        return;
      }
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          [field]: isArray ? [value] : value,
          aircraft_details: [this.props.selected_aircraft_details_id],
        },
      }));
      return;
    }
    if (this.props.mx_mode == "edit") {
      console.log(this.props.vehicle_maintenance_id);
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          ...this.props.vehicle_maintenance_id,
          n_number_string: undefined,
          // id: undefined
        },
      }));
    }
  }
  isHidden(field = "") {
    switch (this.state.form_data.mx_area) {
      case "fuel":
        if (
          field == "mechanic" ||
          field == "inspector" ||
          field == "recurring" ||
          field == "log-details" ||
          field == "mx_type" ||
          field == "has_ads"
        )
          return "hidden";
        // if(field == 'gallons')
        // return "visible";
        break;
      default:
        return "visible";
    }
  }
  ShowGallonsField() {
    if (this.state.form_data.mx_area == "fuel") {
      return (
        <div className={`grid field-container`}>
          <span className="required">Gallons</span>
          <input
            className="form-control"
            name="gallons"
            type="number"
            value={this.state.form_data.gallons}
            onChange={(e) => {
              let syntheticValue = {
                target: {
                  value: "Fuel",
                },
                persist: () => {},
              };
              this.storeFormData(e, "gallons", false, true);
              this.storeFormData(syntheticValue, "work_required");
              this.storeFormData(syntheticValue, "work_performed");
            }}
          />
          <span className="error-message">{this.state.errors["gallons"]}</span>
        </div>
      );
    }
  }
  // setSaveStatus() {
  //   this.setState({ submit_status: "running" });
  // }
  render() {
    const {
      aircraft,
      selected_n_number,
      recurring_mx,
      mechanics,
      handleSubmitNewMxRecord,
      add_new_maintenance_record_status,
      updateMaintenaceRecordById,
      vendors,
      predefined_mx_entries,
      mx_types,
    } = this.props;
    const current_tach =
      this.props.aircraft_details_obj[this.props.selected_aircraft_details_id]
        .current_tach;
    let aircraftOptions = aircraft.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.n_number}
        </option>
      );
    });
    let recurringMxOptions = recurring_mx.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
    let mechanicsOptions = mechanics.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
    let mxTypeOptions = mx_types.map((item, index) => {
      return (
        <option key={index} value={item.name}>
          {item.name}
        </option>
      );
    });
    let vendorOptions = vendors.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.company}
        </option>
      );
    });
    let predefinedOptions = predefined_mx_entries.map((item, index) => {
      return (
        <option key={index} value={item.text}>
          {item.mx_area} | {item.name}
        </option>
      );
    });

    return (
      <React.Fragment>
        <section className="component vehicle-maintenance">
          <SectionTitle
            title={`Logbook / ${this.props.mx_mode} - ${this.props.selected_n_number_string}`}
          />
          <section className="entry-details">
            <div className="detail-head create-log-entry grid">
              <div>Entry Information</div>
              <div>Compliance</div>
              <div>Vendor</div>
              <div>Signatures</div>
            </div>
            <section className="create-log-entry grid">
              <section>
                <div className="grid field-container">
                  <span className="required">Aircraft</span>
                  <select
                    className="form-control"
                    disabled
                    value={this.state.form_data.n_number[0]}
                    onChange={(e) => {
                      this.storeFormData(e, "n_number", true);
                      this.storeFormData(e, "aircraft_details", true);
                    }}
                  >
                    <option>select aircraft</option>
                    {aircraftOptions}
                  </select>
                </div>
                <div className="grid field-container">
                  <span className="required">Date</span>{" "}
                  <input
                    className="form-control"
                    required
                    name="log entry date"
                    type="date"
                    value={this.state.form_data.date}
                    onChange={(e) => this.storeFormData(e, "date", false)}
                  />
                  <span className="error-message">
                    {this.state.errors["date"]}
                  </span>
                </div>
                <div className="grid field-container">
                  <span className="required">Category</span>
                  <select
                    value={this.state.form_data.mx_area}
                    className="form-control"
                    onChange={(e) => this.storeFormData(e, "mx_area", false)}
                  >
                    <option value="">select one</option>
                    <option value="engine">Engine</option>
                    <option value="airframe">Airframe</option>
                    <option value="prop">Propeller</option>
                    <option value="fuel">Fuel</option>
                  </select>
                </div>
                <span className="error-message">
                  {this.state.errors["mx_area"]}
                </span>
              </section>
              {/* compliance section */}
              <section>
                <div className="grid field-container">
                  <span className="required">Hours</span>{" "}
                  <input
                    placeholder="tachometer"
                    className="form-control"
                    name="tach_reading"
                    type="number"
                    value={
                      isNaN(this.state.form_data.tachometer)
                        ? ""
                        : this.state.form_data.tachometer
                    }
                    onChange={(e) => {
                      this.storeFormData(e, "tachometer", false, true);
                    }}
                  />
                  <span className="detail-content col-span-2">
                    Previous Tach:{" "}
                    {Object.keys(this.state.record_closest_to_entered_tach)
                      .length > 0
                      ? this.state.record_closest_to_entered_tach.tachometer
                      : current_tach}
                  </span>
                  <span className="error-message">
                    {this.state.errors["tachometer"]}
                  </span>
                </div>

                {
                  //show gallons field if fuel is entered.
                  this.ShowGallonsField()
                }
                <div
                  className={`grid field-container ${this.isHidden("mx_type")}`}
                >
                  <span className="">MX Type</span>
                  <select
                    value={this.state.form_data.mx_type}
                    className="form-control"
                    onChange={(e) => this.storeFormData(e, "mx_type", false)}
                  >
                    <option>select one</option>
                    {mxTypeOptions}
                  </select>
                </div>
                <span className="error-message">
                  {this.state.errors["mx_type"]}
                </span>
                <div
                  className={`grid field-container ${this.isHidden(
                    "recurring"
                  )}`}
                >
                  <span className="required">Recurring</span>
                  <input
                    className="form-check-input"
                    name="recurring"
                    type="checkbox"
                    checked={this.state.form_data.recurring}
                    onChange={(e) => {
                      this.handleRecurringChange(e);
                    }}
                  />
                </div>

                {this.renderRecurringType(recurringMxOptions)}
                <div
                  className={`grid field-container ${this.isHidden("has_ads")}`}
                >
                  <span className="required">Airworthiness Directive</span>
                  <input
                    className="form-check-input"
                    name="has_ads"
                    type="checkbox"
                    checked={this.state.form_data.has_ads}
                    onChange={(e) => {
                      this.handleAdsChange(e);
                    }}
                  />
                  <span className="error-message">{this.state.ad_message}</span>
                  <section className="ad-center">{this.renderAds()}</section>
                </div>
              </section>
              <section>
                <div className="grid field-container">
                  <span>Vendor</span>
                  <select
                    value={this.state.form_data.vendor}
                    className="form-control"
                    onChange={(e) => this.storeFormData(e, "vendor", true)}
                  >
                    <option>select a vendor</option>
                    {vendorOptions}
                    <option value="add_vendor">add vendor...</option>
                  </select>
                </div>
              </section>
              <section>
                <div
                  className={`grid field-container ${this.isHidden(
                    "mechanic"
                  )}`}
                >
                  <span className="required">Mechanic</span>
                  <select
                    value={this.state.form_data.mechanic}
                    className="form-control"
                    onChange={(e) => this.storeFormData(e, "mechanic", true)}
                  >
                    <option>select mechanic</option>
                    {mechanicsOptions}
                  </select>
                  <span className="error-message">
                    {this.state.errors["mechanic"]}
                  </span>
                </div>
                <div
                  className={`grid field-container ${this.isHidden(
                    "inspector"
                  )}`}
                >
                  <span className="required">Inspector</span>
                  <select
                    value={this.state.form_data.inspector}
                    className="form-control"
                    onChange={(e) => this.storeFormData(e, "inspector", true)}
                  >
                    <option>select inspector</option>
                    {mechanicsOptions}
                  </select>
                  <span className="error-message">
                    {this.state.errors["inspector"]}
                  </span>
                </div>
              </section>
            </section>
          </section>
          <section className={`${this.isHidden("log-details")}`}>
            <div className="detail-head create-log-entry text-entry grid">
              <div>Complaint</div>
              <div>Corrective Action</div>
            </div>
            <section className="create-log-entry text-entry grid">
              <section>
                <div className="grid field-container">
                  <span className="required">Complaint</span>
                  <textarea
                    value={this.state.form_data.work_required}
                    className="form-control"
                    name="work_required"
                    onChange={(e) =>
                      this.storeFormData(e, "work_required", false)
                    }
                  />
                  <span className="error-message">
                    {this.state.errors["work_required"]}
                  </span>
                </div>
              </section>
              <section>
                <div className="grid field-container">
                  <span className="">Work Performed</span>
                  <textarea
                    value={this.state.form_data.work_performed}
                    className="form-control"
                    name="work_performed"
                    onChange={(e) =>
                      this.storeFormData(e, "work_performed", false)
                    }
                  />
                  <span className="error-message">
                    {this.state.errors["work_performed"]}
                  </span>
                </div>
                <div className="grid field-container">
                  <span>Predefined</span>
                  <select
                    // value={this.state.form_data.mechanic}
                    className="form-control"
                    onChange={(e) => {
                      this.handlePredefinedText(e);
                    }}
                  >
                    <option value="">choose a predefined entry</option>
                    {predefinedOptions}
                  </select>
                </div>
              </section>
            </section>
          </section>
          <section className={`${this.isHidden("submit-data")}`}>
            <section className="submit-data">
              <button
                className={`form-control field-container btn btn-primary ld-ext-right ${add_new_maintenance_record_status}`}
                onClick={(e) => {
                  this.props.mx_mode == "create"
                    ? handleSubmitNewMxRecord(
                        e,
                        this.state.form_data,
                        this.handleValidation()
                      )
                    : "";
                  this.props.mx_mode == "edit"
                    ? updateMaintenaceRecordById(
                        e,
                        this.state.form_data,
                        this.handleValidation()
                      )
                    : "";
                  this.storeAdComplianceMethod(e, {}, 0, "next-due-update");
                }}
              >
                Save
                <div className="ld ld-ring ld-spin"></div>
              </button>
            </section>
            {add_new_maintenance_record_status == "success" ? (
              <div className="submit-data">Record created successfully</div>
            ) : (
              ""
            )}
            {add_new_maintenance_record_status == "error" ? (
              <div className="submit-data">
                An error occured. Please try again.
              </div>
            ) : (
              ""
            )}
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default Maintenance;
