import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";
import Loading from "../Loading";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="component aircraft">
        <SectionTitle title="Maintenance Information Dashboard" />
        <div className="component"></div>
      </section>
    );
  }
}
export default Dashboard;
