import React, { Component } from "react";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";
const override = css`
  display: block;
  margin: auto auto;
  border-color: #D76E36;
`;
class Loading extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={`component`}>
        <section>
          <div className="loading">
            <img src="dist/assets/img/prop-loading.gif" />
          </div>
        </section>
      </div>
    );
  }
}
export default Loading;
