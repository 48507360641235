import React, { Component } from "react";
import Loading from "../Loading";
import SectionTitle from "../shared/SectionTitle";
class AircraftDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props.aircraft_obj);
    const { make, model, year, serial_number, registraion_expiry, image } =
      this.props.aircraft_obj[this.props.selected_record_id];
    const {
      oil_filter,
      oil,
      spark_plug,
      spark_plug_torque,
      oil_filter_torque,
      current_tach,
      current_mx_date,
    } =
      this.props.aircraft_details_obj[this.props.selected_aircraft_details_id];
    return (
      <section className="component aircraft-details">
        <SectionTitle
          title={`Aircraft Details / ${this.props.selected_n_number_string}`}
        />
        <section className="entry-details">
          <div className="detail-head create-log-entry grid">
            <div>Registration Details</div>
            <div>Consumables &amp; Specs</div>
            <div>Specifications</div>
            <div></div>
          </div>
          <section className="create-log-entry grid">
            <section>
              <div className="grid field-container">
                <span>Make:</span>
                <div>{make}</div>
              </div>
              <div className="grid field-container">
                <span>Model:</span>
                <div>{model}</div>
              </div>
              <div className="grid field-container">
                <span>Year:</span>
                <div>{year}</div>
              </div>
              <div className="grid field-container">
                <span>Serial Number:</span>
                <div>{serial_number}</div>
              </div>
              <div className="grid field-container">
                <span>Registration Expiry:</span>
                <div>{registraion_expiry}</div>
              </div>
            </section>
            <section>
              <div className="grid field-container">
                <span>Oil:</span>
                <div>{oil}</div>
              </div>
              <div className="grid field-container">
                <span>Oil Filter:</span>
                <div>{oil_filter}</div>
              </div>
              <div className="grid field-container">
                <span>Oil Filter Torque:</span>
                <div>{oil_filter_torque}</div>
              </div>
              <div className="grid field-container">
                <span>Spark Plug:</span>
                <div>{spark_plug}</div>
              </div>
              <div className="grid field-container">
                <span>Spark Plug Torque:</span>
                <div>{spark_plug_torque}</div>
              </div>
            </section>

            <section>
              <div className="grid field-container">
                <span>Tach at last mx:</span>
                <div>{current_tach}</div>
              </div>
              <div className="grid field-container">
                <span>Last MX Date</span>
                <div>{current_mx_date}</div>
              </div>
            </section>
            <section>
              <img src={image[0].url}/>
            </section>
          </section>
        </section>
      </section>
    );
  }
}
export default AircraftDetails;
