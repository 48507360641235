import React, { Component } from "react";
import AircraftList from "./AircraftList";
import SectionTitle from "../shared/SectionTitle";
import Loading from "../Loading";
class Aircraft extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    // if (!this.props.data_load_ran){
    //   this.props.getData();
    // }
    
  }

  renderVehicles(data, user_details, details, method, navigation) {
    return data.map((item, index) => {
      return (
        <AircraftList
          data={item}
          aircraft_details={details}
          user_details={user_details}
          key={index}
          getNextItemDue={method}
          handleNavigation={navigation}
        />
      );
    });
  }
  render() {
    if (this.props.status == "ready") {
      return (
        <section className="component aircraft">
            <SectionTitle title="Maintenance Information Center" />
          <div className="">
            <div className="tabular table-head">
              <div>Aircraft</div>
              <div>Reported Date</div>
              <div>Reported Hours</div>
              <div>Next Item Due by Date</div>
              <div>Days Remaining</div>
              <div>Next Item Due by Hours</div>
              <div>Hours Remaining</div>
              <div>Action</div>
            </div>
            {this.renderVehicles(
              this.props.aircraft_data,
              this.props.user_details,
              this.props.aircraft_details,
              this.props.getNextItemDue,
              this.props.handleNavigation
            )}
          </div>
        </section>
      );
    } else {
      return <Loading componentClass={"aircraft"} />;
    }
  }
}
export default Aircraft;
