import React, { Component } from "react";
import SectionTitle from "./SectionTitle";
import AdEdit from "../maintenance_logs/AdEdit";
import AdAdd from "../maintenance_logs/AdAdd";
import MxDetails from "../maintenance_logs/MxDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fas,
  faCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";

class Drawer extends Component {
  constructor(props) {
    super(props);
  }
  renderForm(form) {
    const { show_drawer, drawer_title } = this.props.state_data;
    const { handleAdAction, updateAdRecordById, createAdRecord } = this.props.functions;
    switch (form) {
      case "AdEdit":
        return (
          <AdEdit
            data={this.props}
            ad_item={this.props.ad_item}
            {...this.props}
            functions={{ updateAdRecordById: updateAdRecordById }}
          />
        );

      case "AdAdd":
        return (
          <AdAdd
            data={this.props}
            ad_item={this.props.ad_item}
            {...this.props}
            functions={{ updateAdRecordById: updateAdRecordById, createAdRecord: createAdRecord }}
          />
        );
        case "mxDetails":
        return (
          <MxDetails
            data={this.props}
            {...this.props}
          />
        );
      default:
        return <div>no content available</div>;
    }
  }

  render() {
    const { show_drawer, drawer_title } = this.props.state_data;
    const { handleDrawToggle, updateAdRecordById } = this.props.functions;
    if (show_drawer) {
      return (
        <section className={`drawer ${show_drawer ? "open" : "close"}`}>
          {/* <div className="section-title">{drawer_title}</div> */}
          <div
            className="icon close"
            onClick={(e) => handleDrawToggle(e, "edit")}
          >
            <FontAwesomeIcon icon={faXmarkCircle} />
          </div>
          <section>{this.renderForm(this.props.content)}</section>
        </section>
      );
    } else {
      return (
        <section
          className={`drawer ${show_drawer ? "open" : "close"}`}
        ></section>
      );
    }
  }
}
export default Drawer;
