import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";
import AdList from "./AdList";
import FilterControls from "./FilterControls";
import Loading from "../Loading";
import Drawer from "../shared/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPlusCircle} from "@fortawesome/free-solid-svg-icons";

class ADCenter extends Component {
  constructor(props) {
    super(props);
    this.handleMxTypeFilter = this.handleMxTypeFilter.bind(this);
    this.handleSearchFilter = this.handleSearchFilter.bind(this);
    this.handleAircraftFilter = this.handleAircraftFilter.bind(this);
    this.state = {
      vehicle_maintenance: [],
      ad_type_filter: ["engine", "airframe", "prop", "recurring"],
      ad_search_filter: "",
      selected_aircraft: null,
      show_drawer: false,
      ad_action_selection: "",
    };
  }
  componentDidMount() {
    //get ad items
    // this.props.get_ad(this.props.selected_n_number_string).then((resp) => {
    //   console.log(this.props.vehicle_maintenance);
    //   this.setState((prevState) => ({
    //     vehicle_maintenance: this.props.vehicle_maintenance,
    //   }));
    //   console.log(resp);
    // });
  }
  componentDidUpdate(prevProps) {
    // const { vehicle_maintenance_obj } = this.props;
    // if (prevProps.vehicle_maintenance_obj != vehicle_maintenance_obj) {
    //   this.setState((prevState) => ({
    //     vehicle_maintenance: this.props.vehicle_maintenance,
    //   }));
    // }
  }
  handleMxTypeFilter(e) {
    e.persist();
    console.log(e);
    if (e.target.checked) {
      this.setState((prevState) => ({
        ad_type_filter: [...prevState.ad_type_filter, e.target.value],
      }));
    }
    if (!e.target.checked) {
      const elements = this.state.ad_type_filter;
      for (let i = 0; i < elements.length; i++) {
        if (elements[i] == e.target.value) {
          elements.splice(i, 1);
        }
      }
      this.setState({ ad_type_filter: elements });
    }
    //do stuff
  }
  handleSearchFilter(e) {
    e.persist();
    this.setState((prevState) => ({
      ad_search_filter: e.target.value,
    }));
  }
  handleAircraftFilter(e) {
    e.persist();
    const aircraft_record_id = e.target.value;
    this.setState({ selected_aircraft: e.target.value });
    this.props
      .get_ad_by_N_Number(this.props.aircraft_obj[aircraft_record_id].n_number)
      .then((resp) => {
        console.log(resp);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  // getOptions(data) {
  //   console.log(data);
  //   return data.map((item, index) => {
  //     if (item.id == this.props.data.mechanic[0]) {
  //       return (
  //         <option selected key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.id} data-id={item.id}>
  //           {item.name}
  //         </option>
  //       );
  //     }
  //   });
  // }
  handleDrawToggle(e, action) {
    this.setState({ ad_action_selection: action });
    switch (action) {
      case "create":
        this.setState((prevState) => ({
          show_drawer: !prevState.show_drawer,
          ad_action_selection: "",
        }));
        return;
    }
  }
  renderACDetailsDetails() {
    if (this.state.selected_aircraft) {
      const { selected_aircraft } = this.state;
      const aircraft_details =
        this.props.aircraft_obj[selected_aircraft].aircraft_details[0];

      return (
        <div>
          <div className="reverse-video">
          <h3 >Aircraft Records - Do Not Destroy</h3>
          <div
            className="icon add"
            onClick={(e) => this.handleDrawToggle(e, "create")}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </div>
          </div>
          <div className="drawer-container">
            <Drawer
              state_data={this.state}
              functions={{
                handleDrawToggle: this.handleDrawToggle,
                createAdRecord: this.props.createAdRecord,
              }}
              ad_item={this.props.data}
              {...this.props}
              {...this.state}
              content={"AdAdd"}
            />
          </div>
          <div className="tabular ad-details">
            <p className="subtext">Manufacturer</p>
            <p>{this.props.aircraft_obj[selected_aircraft].make || ""}</p>
            <p className="subtext">Model</p>
            <p>{this.props.aircraft_obj[selected_aircraft].model || ""}</p>
            <p className="subtext">Tail #</p>
            <p>{this.props.aircraft_obj[selected_aircraft].n_number || ""}</p>
            <p className="subtext">Serial #</p>
            <p>
              {this.props.aircraft_obj[selected_aircraft].serial_number || ""}
            </p>
            <p className="subtext">Current Time</p>
            <p>
              {this.props.aircraft_details_obj[aircraft_details].current_tach ||
                ""}
            </p>
            <p className="subtext">Total Time</p>
            <p>
              {this.props.aircraft_details_obj[aircraft_details].total_time.toFixed(2) ||
                ""}
            </p>
            <p className="subtext">AC Cert Date</p>
            <p>{this.props.aircraft_obj[selected_aircraft].year || ""}</p>
          </div>
        </div>
      );
    }
    return;
  }

  render() {
    if (
      this.props.logBookStatus == "ready" ||
      this.props.logBookStatus == "idle"
    ) {
      // console.log(this.props);
      const { aircraft_ads } = this.props;
      let filtered_ad_items = [];
      let filter = aircraft_ads.filter((ad) => {
        const elements = this.state.ad_type_filter;
        const text = this.state.ad_search_filter;
        // console.log(elements);
        for (let i = 0; i < elements.length; i++) {
          if (
            (ad.mx_area == elements[i] && ad.subject.toLowerCase().includes(text, 0)) ||
            (ad.mx_area == elements[i] && ad.ad_number.toLowerCase().includes(text, 0))
          ) {
            filtered_ad_items.push(ad);
          }
        }
      });
      let ad_items = filtered_ad_items.map((item, index) => {
        return (
          <AdList
            index={index}
            key={index}
            data={item}
            mechanics_obj={this.props.mechanics_obj}
            user_details={this.props.user_details}
            handleNavigation={this.props.handleNavigation}
            handleDeleteMaintenanceRecord={
              this.props.handleDeleteMaintenanceRecord
            }
            updateAdRecordById={this.props.updateAdRecordById}
            update_ad_record_status={this.props.update_ad_record_status}
            {...this.state}
            {...this.props}
          />
        );
      });

      return (
        <section className="component ad-center">
          <SectionTitle title="Ad Center / List View" print={false} />
          <SectionTitle
            title="Airworthiness Directive Compliance Record"
            print={true}
          />
          <FilterControls
            data={aircraft_ads}
            handleMxTypeFilter={this.handleMxTypeFilter}
            handleSearchFilter={this.handleSearchFilter}
            handleAircraftFilter={this.handleAircraftFilter}
            ad_filters={true}
            {...this.state}
            {...this.props}
          />
          <section className="entry-details">
            <div className="table-head ad">
              {this.renderACDetailsDetails()}
              {/* <div>AD #</div>
              <div>Category</div>
              <div>Subject</div>
              <div>Method of Compliance</div>
              <div>Effective Date</div>
              <div>Date of Compliance</div>
              <div>Tach</div>
              <div>Inspector</div>
              <div>Next Due</div>

              <div>Action</div> */}
            </div>
          </section>
          {ad_items}
        </section>
      );
    } else {
      return <Loading componentClass={"data-container"} />;
    }
  }
}
export default ADCenter;
