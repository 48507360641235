import React, { Component } from "react";
class AircraftList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "ready",
      hours: {
        item_due: "--",
        hours_remaining: "--",
        status: "ok",
      },
      dates: {
        item_due: "--",
        days_remaining: "--",
        status: "warning",
      },
    };
  }
  componentDidMount() {
    this.props
      .getNextItemDue(
        this.props.data.aircraft_details[0],
        this.props.data.n_number_string
      )
      .then((resp) => {
        this.setState({ ...resp, status: "ready" });
      });
  }
  componentDidUpdate(prevProps) {
    const { aircraft_details } = this.props.data;
    if (
      prevProps.aircraft_details[aircraft_details[0]].current_tach !=
      this.props.aircraft_details[aircraft_details[0]].current_tach
    ) {
      this.props
        .getNextItemDue(
          this.props.data.aircraft_details[0],
          this.props.data.n_number_string
        )
        .then((resp) => {
          this.setState({ ...resp, status: "ready" });
        });
    }
  }
  handleRecordClick(data) {
    this.props.handleNavigation({}, "aircraft-details", {
      n_number_string: data.n_number_string,
      n_number: data.id,
      ac_details_id: data.aircraft_details[0],
      selected_record_id: data.id,
    });
  }
  render() {
    if (this.state.status == "ready") {
      console.log(this.props.data);
      const {
        make,
        model,
        year,
        photo,
        n_number_string,
        serial_number,
        id,
        aircraft_details,
      } = this.props.data;

      return (
        <section className={`tabular`}>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            {n_number_string}
          </div>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            {this.props.aircraft_details[aircraft_details[0]].current_mx_date}
          </div>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            {this.props.aircraft_details[aircraft_details[0]].current_tach}
          </div>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            {this.state.dates.item_due}
          </div>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            <p className={`status ${this.state.dates.status}`}>
              {this.state.dates.days_remaining}
            </p>
          </div>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            {this.state.hours.item_due}
          </div>
          <div
            onClick={(e) => {
              this.handleRecordClick(this.props.data);
            }}
          >
            <p className={`status ${this.state.hours.status}`}>
              {typeof this.state.hours.hours_remaining == 'number' ? this.state.hours.hours_remaining.toFixed(2):this.state.hours.hours_remaining}
            </p>
          </div>
          <div>
            <select
              className="form-control"
              onChange={(e) => {
                //text of select to get n_number string
                const component =
                  e.target.options[e.target.selectedIndex].dataset.component;
                this.props.handleNavigation(e, component, {
                  n_number_string: n_number_string,
                  n_number: e.target.value,
                  ac_details_id: aircraft_details[0],
                  selected_record_id: id,
                  mx_mode: "create",
                });
              }}
            >
              <option value="">Select Action</option>
              {this.props.user_details.role == "view" ? (
              ""
            ) :
              <option data-component="maintenance" value={id}>
                Add Discrepancy
              </option>}
              <option data-component="logbook" value={id}>
                View Logbook
              </option>
              <option data-component="document-library" value={id}>
                View Documents
              </option>
            </select>
          </div>
        </section>
      );
    } else {
      return (
        <section className={`tabular`}>
          <div> loading ...</div>
        </section>
      );
    }
  }
}
export default AircraftList;
