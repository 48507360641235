import React, { Component } from "react";
import SectionTitle from "../shared/SectionTitle";
import LogbookEntries from "./LogbookEntries";
import Loading from "../Loading";

class PrintLogEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label_size: "dymo-30323",
      label_dimensions: {
        "dymo-30323": { width: 4, height: 2.125 },
        "dymo-1744907": { width: 6, height: 4 },
      },
      font_size: 10,
      text_decoration: "unset",
    };
  }
  componentDidMount() {
    // document.body.style.width = this.state.label_dimensions["dymo-30323"].width;
    // document.body.style.height = this.state.label_dimensions["dymo-30323"].height;
  }
  componentWillUnmount() {
    document.body.style.width = "auto";
    document.body.style.height = "auto";
  }
  renderTimeTypes(type, data) {
    console.log(type);
    switch (type) {
      case "engine":
        return (
          <div className="align-right">
            <span className="label">SMOH: </span>
            <span className="data">{data.tsmoh.toFixed(2)}</span>
          </div>
        );
      case "airframe":
        return (
          <div className="align-right">
            <span className="label">Total Time: </span>
            <span className="data">{data.aircraft_total_time.toFixed(2)}</span>
          </div>
        );
      case "prop":
        return (
          <div className="align-right">
            <span className="label">Total Time: </span>
            <span className="data">{data.propeller_total_time.toFixed(2)}</span>
          </div>
        );
    }
  }

  handleLableSizeChange(e) {
    this.setState({ label_size: e.target.value });
    //document.body.style.width = this.state.label_dimensions[e.target.value].width;
    //document.body.style.height = this.state.label_dimensions[e.target.value].height;
  }
  handleFontSizeChange(e) {
    this.setState({ font_size: e.target.value });
  }
  handleTextDecorationChange(e) {
    this.setState({ text_decoration: e.target.value });
  }
  render() {
    // const labelStyle = {
    //   ,
    //   font: 'arial'
    // };

    const aircraft = this.props.aircraft_obj;
    const mx_record =
      this.props.vehicle_maintenance_obj[this.props.selected_record_id];
    const ac_details =
      this.props.aircraft_details_obj[this.props.selected_aircraft_details_id];
    const mechanics = this.props.mechanics_obj;
    console.log(mechanics[mx_record.mechanic[0]]);
    const {
      id,
      n_number_string,
      date,
      tachometer,
      tsmoh,
      work_performed,
      mx_area,
      aircraft_total_time,
    } = mx_record;
    // const {} = ac_details[this.props.selected_aircraft_details_id];
    const { serial_number, make, model, year } =
      aircraft[ac_details.aircraft[0]];
    const { name, certificate_number } = mechanics[mx_record.mechanic[0]];
    return (
      <section className="print-component">
        <section className="component">
          <section className="filter-controls">
            <div>
            <label className="required form-check-label" >Label Size</label>
            <select
              className="form-control"
              value={this.state.label_size}
              onChange={(e) => {
                this.handleLableSizeChange(e);
              }}
            >
              <option value="dymo-30323">4 x 2.125</option>
              <option value="dymo-1744907">6 x 4</option>
            </select>
            <label className="form-check-label">Font Size</label>
            <input
            className="form-control"
              type="number"
              value={this.state.font_size}
              onChange={(e) => {
                this.handleFontSizeChange(e);
              }}
            ></input>
            <label className="form-check-label">Font Style</label>
            <select
            className="form-control"
              value={this.state.text_decoration}
              onChange={(e) => {
                this.handleTextDecorationChange(e);
              }}
            >
              <option value="unset">choose a style</option>
              <option value="uppercase">Uppercase</option>
              <option value="capitalize">Capitalize</option>
              <option value="lowercase">Lowercase</option>
            </select>
          </div>
          </section>
        </section>

        <section className="print-container">
          <section className={`${this.state.label_size}`}>
            <section className="print-header">
              <div>
                <span className="label">Date: </span>
                <span className="data">{date}</span>
              </div>
              <div className={`align-center ${mx_area}`}>
                <span className="label">Aircraft: </span>
                <span className="data">
                  {make} {model}
                </span>
              </div>
              <div className={`align-right ${mx_area}`}>
                <span className="label">Tach: </span>
                <span className="data">{tachometer.toFixed(2)}</span>
              </div>
              <div className={`${mx_area}`}>
                <span className="label">AC: </span>
                <span className="data">{n_number_string}</span>
              </div>
              <div className={`align-center ${mx_area}`}>
                <span className="label">Serial Number: </span>
                <span className="data">{serial_number}</span>
              </div>
              {this.renderTimeTypes(mx_area, mx_record)}
              {/* <div className="align-right">
                <span className="label">SMOH: </span>
                <span className="data">{tsmoh}</span>
              </div> */}
            </section>
            <section
              className="work-performed"
              style={{ fontSize: `${this.state.font_size}pt`, textTransform: this.state.text_decoration }}
            >
              {work_performed}
            </section>
            <section className="signature-block">
              <div>
                {name} - {certificate_number}
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}
export default PrintLogEntry;
