import React, { Component } from "react";
import ListVendors from "./ListVendors";
import SectionTitle from "../shared/SectionTitle";
import AddVendor from "./AddVendor";
import airtable from "../../utilities/airtable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "ready",
      showVendorForm: false,
      form_mode: "create",
      vendor_id: null,
    };
    this.toggleVendorForm = this.toggleVendorForm.bind(this);
  }

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    // const { aircraft_details } = this.props.data;
    // if (
    //   prevProps.aircraft_details[aircraft_details[0]].current_tach !=
    //   this.props.aircraft_details[aircraft_details[0]].current_tach
    // ) {
    //   this.props
    //     .getNextItemDue(
    //       this.props.data.aircraft_details[0],
    //       this.props.data.n_number_string
    //     )
    //     .then((resp) => {
    //       this.setState({ ...resp, status: "ready" });
    //     });
    // }
  }
  //   handleRecordClick(data) {
  //     this.props.handleNavigation({}, "aircraft-details", {
  //       n_number_string: data.n_number_string,
  //       n_number: data.id,
  //       ac_details_id: data.aircraft_details[0],
  //       selected_record_id: data.id,
  //     });
  //}
  toggleVendorForm(e, mode, id = null) {
    this.setState((prevState) => ({
      showVendorForm: !prevState.showVendorForm,
      form_mode: mode,
      vendor_id: id,
    }));
  }
  renderVendors(data) {
    return data.map((item, index) => {
      return (
        <ListVendors
          data={item}
          key={index}
          toggleVendorForm={this.toggleVendorForm}
          handleDeleteVendorRecord={this.handleDeleteVendorRecord}
          getData={this.props.getData}
        />
      );
    });
  }
  handleDeleteVendorRecord(data, getData = () => {}) {
    let check = confirm("Are you sure you want to delete this record");
    if (check) {
      return airtable
        .deleteVendorRecord(data)
        .then((resp) => {
          console.log(resp);
          getData();
        })
        .catch((error) => {
          console.log("got catch: ", error);
        });
    }
  }
  render() {
    if (this.state.status == "ready") {
      return (
        <section className="component">
          <SectionTitle title="Vendors" />
          <div className="">
            <div onClick={(e) => this.toggleVendorForm(e, "create")}>
              {this.state.showVendorForm == false ? (
                <p className="secondary-btn">
                  <i className="secondary-btn"><FontAwesomeIcon icon={faPlus} />  </i> 
                    Add New
                </p>
              ) : (
                <p className="secondary-btn">  
                  <i lassName="secondary-btn"><FontAwesomeIcon icon={faMinus} />  </i>
                    Close Form
                </p>
              )}
            </div>
            <AddVendor getData={this.props.getData} {...this.state} />
          </div>
          <div className="">
            <div className="tabular table-head">
              <div>Company</div>
              <div>Address</div>
              <div>Phone</div>
              <div>Notes</div>
              <div>Actions</div>
            </div>
            <div>{this.renderVendors(this.props.vendors)}</div>
          </div>
        </section>
      );
    } else {
      return (
        <section className={`tabular`}>
          <div> loading ...</div>
        </section>
      );
    }
  }
}
export default Vendors;
