import axios from "axios";
const base = "appqMD2FhU0wi7ncn";
const token = "Bearer patKDS5enHZFmVR0Y.142c9adaae39afd3a6a7f513a52fa0c981ec1afb5a6cc2b05d23a7cd29bbc1b9";
// import Promise from "Promise";
const appRequest = {
  getUserById: (id = "") => {
    //accept either n_number or record id
    let suffix = `&filterByFormula={auth0_id}='${id}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/users/?view=master${suffix}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        console.log(resp);
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getAllAircraft: (user = []) => {
    console.log(user);
    const authorizedUser = user.toString();
    console.log(authorizedUser);
    let suffix = `&filterByFormula=FIND('${authorizedUser}',{auth0_id_array})`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/aircraft?view=grid_view${suffix}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getAircraftDetails() {
    // let suffix = `&filterByFormula={n_number_string}='${id}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/aircraft_details?view=grid_view`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getMxType() {
    // let suffix = `&filterByFormula={n_number_string}='${id}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/mx_type?view=grid_view`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getVendors() {
    // let suffix = `&filterByFormula={n_number_string}='${id}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/vendor?view=master`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  createVendorRecord:(data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/vendor/`;
    console.log("data:", ...data);
    return axios({
      url: url,
      method: "POST",
      headers: {
        Authorization: token,
      },
      data: {
        records: [
          {
            fields: {
              ...data,
            },
          },
        ],
      },
    })
      .then((resp) => {
        //console.log(resp);
        return resp.data;
      })
      .catch((err) => {
        //console.log(err);
        return Promise.reject(err);
      });
  },
  updateVendorRecordById: (data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/vendor/${data.id}`;
    console.log("update Records: ", data);
    delete data.id;
    console.log("update Records id: ", data);
    return axios({
      url: url,
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      //delete the id field before we send it to airtable
      data: {
        fields: {
          ...data,
        },
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteVendorRecord: (data = []) => {
    let url = `https://api.airtable.com/v0/${base}/vendor/${data}`;
    console.log("delete Records: ", data);
    return axios({
      url: url,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getVendorById: (id = "") => {
    //accept either n_number or record id
    // let suffix = `&filterByFormula={n_number_string}='${n_number}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/vendor/${id}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        // data.push(...resp.data.records);
        return resp.data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getPredefinedMxEntries() {
    
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/predefined_mx_entries?view=master`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getDocumentLibrary(user) {

    const authorizedUser = user.toString();
    let suffix = `&filterByFormula=FIND('${authorizedUser}',{user_id_array})`;
    // let suffix = `&filterByFormula={n_number_string}='${id}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/document_library?view=master${suffix}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getRecurringMX() {
    // let suffix = `&filterByFormula={n_number_string}='${id}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/recurring_mx_items?view=grid_view`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getAllMechanics: () => {
    let suffix = "";
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/mechanic?view=grid_view`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getAllRecurringTypes: () => {
    let suffix = "";
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/recurring_mx_items?view=grid_view`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getMxN_Number: (n_number = "", type = "") => {
    //accept either n_number or record id
    let suffix = `&filterByFormula={n_number_string}='${n_number}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/maintenance?view=grid_view${suffix}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
  getMxById: (id = "") => {
    //accept either n_number or record id
    // let suffix = `&filterByFormula={n_number_string}='${n_number}'`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/maintenance/${id}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        // data.push(...resp.data.records);
        return resp.data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },

  updateAdComplianceMethodById:(data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/ads/${data.id}`;
    console.log("update Records: ", data);
    delete data.id;
    console.log("update Records updated id: ", data);
    return axios({
      url: url,
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      
      data: {
        fields: {
          ...data,
          
        },
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return err;
      });
  },
//update a batch of Ad methods
  updateAdComplianceMethodByIds:(data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/ads/`;
    console.log("update Records: ", data);
    delete data.id;
    return axios({
      url: url,
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      
      data: {
          records:data
        },
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return err;
      });
  },
  updateAdRecordById: (data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/ads/${data.id}`;
    console.log("update Records: ", data);
    console.log("updated Records id: ", data);
    delete data.id;
    return axios({
      url: url,
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      //delete the id field before we send it to airtable
      data: {
        fields: {
          ...data,

        },
      },
    })
      .then((resp) => {
        console.log("success");
        return resp.data;
      })
      .catch((err) => {
        console.log("err:", err);
        return Promise.reject(err);
      });
  },
  createAdRecord: (data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/ads`;
    console.log("data:", ...data);
    return axios({
      url: url,
      method: "POST",
      headers: {
        Authorization: token,
      },
      data: {
        records: [
          {
            fields: {
              ...data,
            },
          },
        ],
      },
    })
      .then((resp) => {
        //console.log(resp);
        return resp.data;
      })
      .catch((err) => {
        //console.log(err);
        return Promise.reject(err);
      });
  },
  updateMaintenaceRecordById: (data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/maintenance/${data.id}`;
    console.log("update Records: ", data);
    delete data.id;
    console.log("update Records deleted id: ", data);
    return axios({
      url: url,
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      //delete the id field before we send it to airtable
      data: {
        fields: {
          ...data,
          // // "Pilot": [
          // //   "recjdYTUV4ZzDCtsk"
          // // ],
          // "Duty Time In": data.fields["Duty Time In"],
          // "Duty Time Out": data.fields["Duty Time Out"],
          // "Flight Hours": data.fields["Flight Hours"]*1,
          // "Other Commercial Flying": data.fields["Other Commercial Flying"]*1,
          // "Comments": data.fields["Comments"],
        },
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteMaintenanceRecord: (data = []) => {
    let url = `https://api.airtable.com/v0/${base}/maintenance/${data}`;
    console.log("delete Records: ", data);
    return axios({
      url: url,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        return err;
      });
  },
  createMaintenanceRecord: (data = {}) => {
    let url = `https://api.airtable.com/v0/${base}/maintenance/`;
    console.log("data:", ...data);
    return axios({
      url: url,
      method: "POST",
      headers: {
        Authorization: token,
      },
      data: {
        records: [
          {
            fields: {
              ...data,
            },
          },
        ],
      },
    })
      .then((resp) => {
        //console.log(resp);
        return resp.data;
      })
      .catch((err) => {
        //console.log(err);
        return Promise.reject(err);
      });
  },
  get_ad_by_N_Number: (n_number = "", type = "") => {
    //accept either n_number or record id
    console.log(n_number);
    let suffix = `&filterByFormula=FIND('${n_number}',{aircraft_array})`;
    let data = [];
    let url = `https://api.airtable.com/v0/${base}/ads?view=grid_view${suffix}`;
    // //console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        data.push(...resp.data.records);
        return data;
      })
      .catch((error) => {
        // //console.log(error);
        return Promise.reject(error);
      });
  },
};
export default appRequest;
