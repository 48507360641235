import React, { Component } from "react";
import ReactDOM from "react-dom";

class FourOFour extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    this.props.fourOFourClassMe();
  }
  render() {
    return (
      <div className="four-o-four">
        <h1>This page has gone off the grid.</h1>
        <div>
          <h2>404</h2>
          <p>Page cannot be found</p>
        </div>
      </div>
    );
  }
}

export default FourOFour;
