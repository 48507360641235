import React, { Component } from "react";
class ListVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "ready",
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    // const { aircraft_details } = this.props.data;
    // if (
    //   prevProps.aircraft_details[aircraft_details[0]].current_tach !=
    //   this.props.aircraft_details[aircraft_details[0]].current_tach
    // ) {
    //   this.props
    //     .getNextItemDue(
    //       this.props.data.aircraft_details[0],
    //       this.props.data.n_number_string
    //     )
    //     .then((resp) => {
    //       this.setState({ ...resp, status: "ready" });
    //     });
    // }
  }
  //   handleRecordClick(data) {
  //     this.props.handleNavigation({}, "aircraft-details", {
  //       n_number_string: data.n_number_string,
  //       n_number: data.id,
  //       ac_details_id: data.aircraft_details[0],
  //       selected_record_id: data.id,
  //     });
  //}
  render() {
    if (this.state.status == "ready") {
      const {
        company,
        address,
        phone_number,
        notes,
        id
      } = this.props.data;

      return (
        <section className={`tabular`}>
          <div>{company}</div>
          <div>
              {address}
          </div>
          <div>{phone_number}</div>
          <div>
            
              {notes}
            
          </div>
          <div>
            <a href="" onClick={(e) => this.props.toggleVendorForm(e, "edit", id)}>edit</a> | <a href="" onClick={(e)=>this.props.handleDeleteVendorRecord(id, this.props.getData)}>delete</a>
          </div>
        </section>
      );
    } else {
      return (
        <section className={`tabular`}>
          <div> loading ...</div>
        </section>
      );
    }
  }
}
export default ListVendors;
